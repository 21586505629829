import React, { createContext, memo, PropsWithChildren, useContext } from 'react';
import { useAuth } from '../hooks/useAuth';
import createApi, { IApiServices } from '../services';

const ApiContext = createContext({} as IApiServices);

export const ApiContextProvider = memo(({ children }: PropsWithChildren<{}>) => {
    const [token, setToken] = React.useState(null);

    // get azure instance and account
    const { account, getAccessToken } = useAuth();

    function updateToken(token: any) {
        setToken(token);
    }

    // when getAccessToken changes due to azure info updating, rerun it
    React.useEffect(() => {
        async function generateAccessToken() {
            await getAccessToken(updateToken);
        }

        generateAccessToken();
    }, [getAccessToken]);

    // when token updates, attempt to create the service
    const api = React.useMemo(() => {
        return createApi(token, account);
    }, [token, account]);

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
});

export function useApi() {
    return useContext(ApiContext);
}
