import React from 'react';
import { RequirementFieldType, RequirementFormFieldWithError } from '../../models/app-types';
import { Field, FieldProps } from 'formik';
import { DateInput } from '../DateInput';
import { NumberInput } from '../NumberInput';
import { MedSolLabel } from '../MedSolLabel';
import { TextInput } from '../TextInput';
import { MedSolValidationMessage } from '@ms/medsol-components';
import styled from 'styled-components';
import { SelectInput } from '../SelectInput';

const StyledRequirementField = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: ${(props) => props.theme.spacing(1)};
`;

// object literal that switches input component based on requirement type
const generateRequirementInput = (type: RequirementFieldType) =>
    ({
        Text: TextInput,
        Date: DateInput,
        Number: NumberInput,
        Boolean: TextInput,
        Select: SelectInput,
        '': null,
    }[type]);

export function RequirementField({
    name,
    label,
    type,
    error,
    options = [],
    isSpecialDoc,
    isRequired = false,
    disabled = false,
}: RequirementFormFieldWithError) {
    if (!type) return null;

    return (
        <StyledRequirementField>
            {/* this is a check for specialDoc and not to display a label if it is a special upload doc. */}
            {isSpecialDoc && <MedSolLabel for={name}>{label}</MedSolLabel>}
            {type === 'Date' && (
                <Field name={name}>
                    {({ field, form }: FieldProps) => (
                        <DateInput
                            {...field}
                            placeholder={isRequired ? `${label} *` : label}
                            status={error ? 'error' : 'default'}
                            onChange={(value: any) => {
                                form.setFieldValue(name, value.currentTarget.value);
                            }}
                            disabled={disabled}
                        />
                    )}
                </Field>
            )}
            {type === 'Select' && (
                <Field name={name}>
                    {({ field, form }: FieldProps) => (
                        <SelectInput
                            name={name}
                            placeholder={isRequired ? `${label} *` : label}
                            options={options}
                            status={error ? 'error' : 'default'}
                            value={options.filter((option) => option.value === field.value)}
                            onChange={(option) => {
                                form.setFieldValue(name, option.value);
                            }}
                            isDisabled={disabled}
                            onBlur={undefined} // onBlur should be optional here, but errors out if not defined
                        />
                    )}
                </Field>
            )}

            {type !== 'Select' && type !== 'Date' && (
                <Field
                    key={name}
                    name={name}
                    component={generateRequirementInput(type)}
                    placeholder={isRequired ? `${label} *` : label}
                    status={error && 'error'}
                    disabled={disabled}
                />
            )}

            <MedSolValidationMessage text={error || ''} status={'error'} />
        </StyledRequirementField>
    );
}
