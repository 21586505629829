import React from 'react';
import { Formik, Form } from 'formik';
import { FileSelector } from '../../../../components/FileSelector';
import { useTravelerId } from '../../../../hooks/useTravelerId';
import { Hr } from '../../../../components/Hr';
import { ButtonPrimary } from '../../../../components/ButtonPrimary';
import { PadiDrawerContent, PadiDrawerFooter } from '../../../../components/PadiDrawer';
import { useDrawer } from '../../../../hooks/useDrawer';
import { MedSolSpace } from '@ms/medsol-components';
import { RequirementField } from '../../../../components/RequirementField';
import { useProfileMetadata } from '../../../../hooks/useProfileMetadata';
import { CertificationFormType } from '../types';
import { useQueryClient } from 'react-query';
import { generateCertificationURL } from '../../../../utilities/app-utilities';
import { useUploadCertificationMutation } from '../hooks';
import { CertificationLogic } from '../logic';

interface CertificationFormProps {
    certification: CertificationFormType;
    isActive: boolean;
}

export function CertificationForm({ certification, isActive = true }: CertificationFormProps) {
    const { travelerId } = useTravelerId();
    const { mutate, status: mutationStatus } = useUploadCertificationMutation();
    const { closeDrawer } = useDrawer();
    const { certificationOptions } = useProfileMetadata();
    const queryClient = useQueryClient();

    if (!isActive) return null;

    return (
        <Formik
            initialValues={certification}
            validationSchema={CertificationLogic.getCertificationFormValidationSchema}
            onSubmit={(values) => {
                console.log('submitting values for', values);
                mutate(
                    { travelerId, payload: values },
                    {
                        onSuccess: () => {
                            if (values.certificationId !== null) {
                                queryClient.invalidateQueries(generateCertificationURL(travelerId));
                                queryClient.invalidateQueries(['certification', values.certificationId]);
                            }
                            closeDrawer();
                        },
                        onError: (error) => {
                            console.log('error', error);
                        },
                    }
                );
            }}
        >
            {({ errors, isSubmitting, setFieldValue }) => (
                <>
                    <PadiDrawerContent>
                        <Form id="add-document-form">
                            <RequirementField
                                type="Select"
                                options={certificationOptions}
                                name="certificationType"
                                label="Certification Type"
                                isRequired
                                error={errors['certificationType']}
                                disabled={certification.certificationType !== ''} // disabled if editing exising cert
                            />
                            <MedSolSpace h={4} />
                            <RequirementField name="expirationDate" label="Expiration Date" type="Date" isRequired error={errors['expirationDate']} />
                            <Hr />
                            <FileSelector
                                name="frontFile"
                                buttonText="Upload Front"
                                file={undefined}
                                setFile={(file) => setFieldValue('frontFile', file)}
                                useDetails
                                errorText={errors['frontFile']}
                            />
                            <Hr />
                            <FileSelector
                                name="backFile"
                                buttonText="Upload Back"
                                file={undefined}
                                setFile={(file) => setFieldValue('backFile', file)}
                                useDetails
                                errorText={errors['backFile']}
                            />
                            <Hr />
                        </Form>
                    </PadiDrawerContent>
                    <PadiDrawerFooter>
                        <ButtonPrimary type="submit" form="add-document-form" disabled={false} isLoading={isSubmitting || mutationStatus === 'loading'}>
                            Save
                        </ButtonPrimary>
                    </PadiDrawerFooter>
                </>
            )}
        </Formik>
    );
}
