import { ILicenseModel } from '@ms/credentials/dist/models/license.models';
import { ExpirationStatusEnum } from '@ms/credentials/dist/models/expiration-status';
import * as Yup from 'yup';
import { ITaskCardPropsExtended, Status } from '../../../models/app-types';
import { DATE_FORMAT_REGEX, formatDate, generateTaskStatusIcon } from '../../../utilities/app-utilities';
import { LicenseFormType } from './types';
import { CredsLogic } from '@ms/credentials/dist/logic/creds.logic';

export const LicenseLogic = {
    getEmptyLicense(): LicenseFormType {
        return {
            backFile: undefined,
            backSideBlobId: '',
            backSideUri: '',
            createdDateTime: undefined,
            daysLeftTillExpiration: 0,
            expirationDate: 0,
            frontFile: undefined,
            frontSideBlobId: '',
            frontSideUri: '',
            isActive: false,
            isCompact: false,
            isExpiringSoon: false,
            licenceId: '',
            licenceNumber: '',
            stateLicence: '',
            status: ExpirationStatusEnum.UpToDate,
        };
    },
    getEmptyCompactLicense(): LicenseFormType {
        return {
            ...LicenseLogic.getEmptyLicense(),
            isCompact: true,
        };
    },

    getLicenseFormValidationSchema() {
        return Yup.object({
            stateLicence: Yup.string().required('This field is required'),
            licenceNumber: Yup.string().max(50, 'Must be 50 characters or less').required('This field is required'),
            expirationDate: Yup.string().required('This field is required').matches(DATE_FORMAT_REGEX['YYYY-MM-DD'], 'Must be a valid date'),
            frontFile: Yup.mixed().required('This field is required'),
            backFile: Yup.mixed().required('This field is required'),
        });
    },

    getLicenseFormData: (license: LicenseFormType): FormData => {
        const formData = new FormData();

        Object.keys(license).forEach((key) => {
            if (
                ![
                    'daysLeftTillExpiration',
                    'status',
                    'versionNumber',
                    'isComplete',
                    'backSideBlobId',
                    'backSideUri',
                    'frontSideBlobId',
                    'frontSideUri',
                ].includes(key) &&
                !key.startsWith('created') &&
                !key.startsWith('updated')
            ) {
                // @ts-ignore - not sure what the issue is here yet
                formData.append(key, license[key]);
            }
        });

        return formData;
    },

    generateLicenseTaskCardProps(license: ILicenseModel): ITaskCardPropsExtended {
        return {
            id: license.licenceId,
            source: 'license',
            title: this.getFormattedTitle(license.stateLicence, license.isCompact),
            statusDescription: this.getStatusDescription(license.isComplete || false, license.expirationDate as Date, license.status),
            isResettable: false,
            isSelected: false,
            isLocked: false,
            hasHistory: false,
            isSelectable: false,
            status: license.isComplete ? Status.Done : Status.New,
            documentHistory: [],
            viewableUri: '',
            iconName: generateTaskStatusIcon(license.isComplete ? Status.Done : Status.New),
            previawableUris: [license.frontSideUri || '', license.backSideUri || ''],
        };
    },
    getStatusDescription(isComplete: ILicenseModel['isComplete'], expirationDate: ILicenseModel['expirationDate'], status: ILicenseModel['status']): string {
        return CredsLogic.getCredTileTextToDisplay(isComplete || false, expirationDate as Date, status);
    },
    getFormattedTitle(stateLicense?: ILicenseModel['stateLicence'], isCompact?: ILicenseModel['isCompact']): string {
        if (!stateLicense) return '';
        return `${stateLicense} ${isCompact ? 'Compact' : 'State'} License`;
    },
    getFormattedCompletedDate(date?: Date): string {
        if (!date) return '';
        return `Completed: ${formatDate(date)}`;
    },
    getFormattedExpirationDate(expirationDate?: ILicenseModel['expirationDate']): string {
        if (!expirationDate) return '';
        return `Exp Date: ${formatDate(expirationDate)}`;
    },
    getFormattedLicenseNumber(licenseNumber?: ILicenseModel['licenceNumber']): string {
        if (!licenseNumber) return '';
        return `# ${licenseNumber}`;
    },
    convertLicenseModelToLicenseForm(license: ILicenseModel): LicenseFormType {
        return {
            ...license,
            licenceNumber: license.licenceNumber || '', // make sure we have a string
            expirationDate: 0,
            frontFile: undefined, // convert to File object
            backFile: undefined, // convert to File object
        };
    },
};
