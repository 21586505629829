// This component can be a wrapper to any element where you want to implement click-outside autoclose.

import React from 'react';
import { useClickOutsideOrEscListenerRef } from '../../hooks/useClickOutsideOrEscListenerRef';

interface IDialogProps {
    onClose: () => void;
    children: React.ReactNode;
}

export function ClickOutSideWrapper(props: IDialogProps) {
    const { onClose, children } = props;
    const ref = useClickOutsideOrEscListenerRef(onClose); //onClose method is called when the user clicks outside of your reffered component
    return <div ref={ref}>{children}</div>;
}
