import { AxiosError, AxiosInstance } from 'axios';

function defaultErrorHandler(error: AxiosError<{ data: {} }>): Promise<{}> {
    return Promise.reject(error.response);
}

export default function BaseAPI(axiosInstance: AxiosInstance, urlPrefix: string, urlSuffix: string, token: string | null) {
    const axios = axiosInstance;

    async function requestInterceptor(config: any) {
        config.headers.authorization = `Bearer ${token}`;
        config.headers.contentType = 'application/json';
        config.baseURL = `${urlPrefix}/${urlSuffix}`;

        return config;
    }

    if (token) {
        axios.interceptors.request.use(requestInterceptor, defaultErrorHandler);
    }

    async function get<T>(url: string): Promise<T> {
        return axios.get(url).then((resp) => {
            return resp.data.data;
        });
    }
    async function otherGet<T>(url: string): Promise<T> {
        return axios.get(url).then((resp) => {
            return resp.data;
        });
    }

    async function post<T>(url: string, payload: {}): Promise<T> {
        return axios.post(url, payload).then((resp) => {
            return resp.data;
        });
    }

    async function postForFiles<T>(url: string, formData: FormData): Promise<T> {
        return axios
            .post(url, formData, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((resp) => {
                return resp.data;
            });
    }

    async function remove<T>(url: string): Promise<T> {
        return axios.delete(url).then((resp) => {
            return resp.data;
        });
    }

    async function upload<T>(url: string, formData: FormData): Promise<T> {
        return axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((resp) => {
                return resp.data;
            });
    }

    // Using the functional JS object pattern to avoid classes.
    return {
        get,
        otherGet,
        post,
        upload,
        postForFiles,
        remove,
    };
}
