import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LicenseLogic } from './logic';
import { useApi } from '../../../contexts/api-context';
import { useTravelerId } from '../../../hooks/useTravelerId';
import { generateLicenseURL, generatePadiDocumentsAPIWithTravelerId } from '../../../utilities/app-utilities';

export function useUploadLicenseMutation() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();
    const queryClient = useQueryClient();

    //@ts-ignore
    return useMutation(PadiAPI?.uploadLicense, {
        enabled: !!PadiAPI && !!travelerId,
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
            queryClient.invalidateQueries(generateLicenseURL(travelerId));
        },
    });
}

export function useGetLicenseQuery(licenseId: string) {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();

    return useQuery(['license', licenseId], () => PadiAPI?.getLicense(travelerId, licenseId), {
        enabled: PadiAPI !== null && travelerId !== '' && licenseId !== '',
        staleTime: 5 * 60 * 1000, // 5 minutes
    });
}

export function useGetLicensesQuery() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();

    const { data, ...rest } = useQuery(generateLicenseURL(travelerId), () => PadiAPI?.getLicenses(travelerId), {
        enabled: !!PadiAPI && !!travelerId,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    return React.useMemo(() => {
        const licenseTasks = data?.map((license) => LicenseLogic.generateLicenseTaskCardProps(license)) || [];

        return {
            data,
            licenseTasks,
            ...rest,
        };
    }, [data, rest]);
}
