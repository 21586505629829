import React from 'react';
import styled from 'styled-components';

export const StyledImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledImageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledImage = styled.img<{ isLoaded: boolean; height?: string; width?: string }>`
    width: ${(props) => (props.width !== '' ? props.width : '285px')};
    height: ${(props) => (props.height !== '' ? props.height : '177px')};
    background-color: ${(props) => (props.isLoaded ? 'unset' : '#f5f5f5')};
    border-radius: 4px;
    cursor: pointer;
`;

export function Image({
    src,
    alt,
    className = '',
    height = '',
    width = '',
}: {
    src: string;
    alt: string;
    className?: string;
    height?: string;
    width?: string;
}) {
    const [isLoaded, setIsLoaded] = React.useState(false);

    return (
        <StyledImage
            height={height}
            width={width}
            src={src}
            alt={alt}
            onClick={() => window?.open(src)}
            className={className}
            isLoaded={isLoaded}
            onLoad={() => setIsLoaded(true)}
        />
    );
}
