import { ALLOWED_AD_GROUPS, LIMITED_ALLOWED_AD_GROUP, ALLOWED_DELETE_GROUPS } from '../constants/app-constants';
import { NullableOption } from '@microsoft/microsoft-graph-types';

export function doesUserHaveAdminPermission(groupNames: string[]): boolean {
    if (!groupNames || !groupNames.length) {
        return false;
    }

    // check if any of the users group names matches an allowed ad group
    return groupNames.some((name: NullableOption<string> | undefined) => ALLOWED_AD_GROUPS.includes(name as string));
}

export function doesUserHaveCMPermission(groupNames: string[]) {
    if (!groupNames || !groupNames.length) {
        return false;
    }

    // check if any of the users group names matches an limited allowed ad group
    return groupNames.some((name: NullableOption<string> | undefined) => LIMITED_ALLOWED_AD_GROUP.includes(name as string));
}

export function doesUserHaveDeletePermission(groupNames: string[]) {
    if (!groupNames || !groupNames.length) {
        return false;
    }

    // check if any of the users group names matches the CM group
    return groupNames.some((name: NullableOption<string> | undefined) => ALLOWED_DELETE_GROUPS.includes(name as string));
}
