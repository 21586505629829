import React from 'react';
import { Typography } from '@ms/medsol-components';
import styled from 'styled-components';

const StyledContactTypeTag = styled.div`
    width: ${(props) => props.theme.spacing(12)};
    height: 18px; // spacing(4.5) doesn't exist
    background: ${(props) => props.theme.gradients['btn-orange']};
    text-transform: uppercase;
    padding: ${(props) => `${props.theme.spacing(0.5)} ${props.theme.spacing(2)}`};
    color: ${(props) => props.theme.colors.White};
    border-radius: ${(props) => props.theme.spacing(1)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`;

const StyledTypography = styled(Typography)`
    line-height: normal; // centers the text
`;

export const ContactTypeTag = React.memo(({ contactType = '' }: { contactType: string }) => {
    return (
        <StyledContactTypeTag>
            <StyledTypography variant="input_label" weight={600}>
                {contactType}
            </StyledTypography>
        </StyledContactTypeTag>
    );
});
