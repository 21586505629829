import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { REACT_QUERY_CONFIG } from '../constants/react-query';
import { MedSolTheme } from '@ms/medsol-components';
import { ThemeProvider } from 'styled-components';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { loginRequest, msalConfig } from '../config/auth-config';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthError } from '../components/AuthError';
import { AuthLoading } from '../components/AuthLoading';
import { ApiContextProvider } from './api-context';
import { AppInsightsProvider } from './AppInsightsProvider';
// import { ModalContextProvider } from './ModalContextProvider';

const queryClient = new QueryClient({
    defaultOptions: REACT_QUERY_CONFIG,
});

// this will give us intellisense(auto-complete) for our theme
declare module 'styled-components' {
    type Theme = typeof MedSolTheme;
    export interface DefaultTheme extends Theme {}
}

export function AppContextProvider({ children }: { children: React.ReactNode }) {
    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <QueryClientProvider client={queryClient}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={{ ...loginRequest }}
                    errorComponent={AuthError}
                    loadingComponent={AuthLoading}
                >
                    <AppInsightsProvider>
                        <ApiContextProvider>
                            <Router>
                                <ThemeProvider theme={MedSolTheme}>{children}</ThemeProvider>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </Router>
                        </ApiContextProvider>
                    </AppInsightsProvider>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </QueryClientProvider>
    );
}
