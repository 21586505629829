import React from 'react';
import create, { State } from 'zustand';
import { DrawerAddDocument } from '../components/DrawerAddDocument';
import { DrawerDocumentDetails, IDocumentDetailsDrawerProps } from '../components/DrawerDocumentDetails';
import { DrawerDownloadDocuments } from '../components/DrawerDownloadDocuments';
import { ITaskCardPropsExtended } from '../models/app-types';

interface IDrawers {
    downloadDocuments: () => React.ReactNode;
    documentDetails: (props: IDocumentDetailsDrawerProps) => JSX.Element;
    addDocument: () => React.ReactNode;
    '': () => '';
}

type DrawerOptions = keyof IDrawers;

const Drawers: IDrawers = {
    downloadDocuments: DrawerDownloadDocuments,
    documentDetails: DrawerDocumentDetails,
    addDocument: DrawerAddDocument,
    '': () => '',
};

export function getDrawerContent(key: DrawerOptions) {
    return Drawers[key];
}

interface DrawerState extends State {
    isOpen: boolean;
    drawerKey: DrawerOptions;
    setDrawerKey: (key: DrawerOptions) => void;
    openDrawer: (key?: DrawerOptions) => void;
    closeDrawer: () => void;
    getContent: () => void;
    drawerProps?: unknown;
    openDocumentDetailsDrawer: (selectedTask: ITaskCardPropsExtended) => void;

}

// store
export const useDrawer = create<DrawerState>((set, get) => ({
    isOpen: false,
    drawerKey: '',
    drawerProps: {},
    setDrawerKey: (key: DrawerOptions) => set(() => ({ drawerKey: key })),
    openDrawer: (key?: DrawerOptions) =>
        set(() => {
            const newObj = { isOpen: true };

            if (key) {
                // @ts-ignore
                newObj.drawerKey = key;
            }
            return newObj;
        }),
    closeDrawer: () => set(() => ({ isOpen: false, drawerKey: '' })),
    getContent: () => Drawers[get().drawerKey],
    openDocumentDetailsDrawer(selectedTask: ITaskCardPropsExtended) {
        set(() => ({
            isOpen: true,
            drawerKey: 'documentDetails',
            drawerProps: {selectedTask},
        }));
    },
}));

// selectors
// const useDrawerSelectors = {
//     isOpen: (state: DrawerState) => state.isOpen,
//     drawerKey: (state: DrawerState) => state.drawerKey,
//     setDrawerKey: (state: DrawerState) => state.setDrawerKey,
//     openDrawer: (state: DrawerState) => state.openDrawer,
//     closeDrawer: (state: DrawerState) => state.closeDrawer,
//     getDrawerContent: (state: DrawerState) => state.getContent,
// };
