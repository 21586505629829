import { ISelectOption } from '@ms/medsol-components';
import { ITaskCardProps } from '../components/TaskCard';

export enum Status {
    New = 1,
    Done = 2,
    Action = 3,
    InProgress = 4,
    Pending = 5, //This is here since the beginning, don't think this is being used.
    Processing = 10, //Added on 10/30/2023 to support docuSign flow and its temporary status(lasts about 20-30 secs only)
}

export enum OrderBy {
    StatusIncomplete = 'Status Incomplete',
    StatusCompleted = 'Status Completed',
    TitleAsc = 'Title Ascending',
    TitleDesc = 'Title Descending',
    Default = 'Status Incomplete',
}

export enum StatusExtended {
    New = 1,
    Done = 2,
    Action = 3,
    InProgress = 4,
    Pending = 5,
    Processing = 10, //Added on 10/30/2023 to support docuSign flow and its temporary status(lasts about 20-30 secs only)
    None,
    Asc = 'asc',
    Desc = 'desc',
}

export enum AuditLogActions {
    Created = 1,
    Viewed = 2,
    Completed = 3,
    Reset = 4,
    Updated = 5,
    Approved = 6,
    Rejected = 7,
}

export enum DELETE_DOCUMENT_REASON {
    WrongTraveler = 1,
    WrongAsset = 2,
}

export const OrderByOptions = [
    { title: 'Status Incomplete', value: StatusExtended.New },
    { title: 'Status Completed', value: StatusExtended.Done },
    { title: 'Title Ascending', value: StatusExtended.Asc },
    { title: 'Title Descending', value: StatusExtended.Desc },
];

export enum RequirementType {
    Uncategorized = -1,
    PulseProtocol = 0,
    DocumentLibraryTaa = 1,
    DocumentLibraryResume = 2,
    DocumentLibraryCwo = 3,
    DocumentLibraryFullApplication = 4,
    GuardianI9 = 99,
    SspProtocol = 1000,
}

export interface ITaskCardPropsExtended extends ITaskCardProps {
    source: 'padi' | 'license' | 'certification';
    isResettable: boolean;
    isSelected: boolean;
    onClick?: (() => Window | any) | undefined;
    isLocked?: boolean; // whether the document is locked due to missing permissions
    hasHistory?: boolean;
    isSelectable?: boolean; // deprecate?
    handleSelectTask?: () => void;
    createdDate?: Date | string;
    isFullApplicationAdminModifiable?: boolean;
    documentHistory: HistoricalDocumentDTO[];
    viewableUri: string;
    previawableUris?: string[];
    resetId?: string;
}

export type HistoricalDocumentDTO = {
    uri: string;
    createdDateTime: Date;
    documentId?: string | null;
    createdBy?: string | null;
};

export type RequirementFieldType = 'Text' | 'Number' | 'Date' | 'Boolean' | 'Select' | '';

export type RequirementFormField = {
    name: string;
    label: string;
    value: any;
    createdDateTime: Date;
    type: RequirementFieldType;
    formFieldId: string;
    protocolAttributeId: string;
    requirementId: string;
    isRequired: boolean;
    systemId: number;
    sourceTypeId: number;
    submissionVersion: number;
    isSpecialDoc?: boolean; // this is exception for the special document only.
    disabled?: boolean;
};

//export type RequirementFormFieldWithError = RequirementFormField & { error?: string | string[] };
export type RequirementFormFieldWithError = Pick<RequirementFormField, 'name' | 'label' | 'type' | 'isSpecialDoc' | 'disabled'> & {
    error?: string;
    isRequired?: boolean;
    options?: ISelectOption[];
};

export type PadiDocumentType = 'Undefined' | 'Upload' | 'PandaDoc' | 'Universal' | 'Guardian' | 'ApplicationResumeProfReference';
export type PadiRequirementType =
    | 'Uncategorized'
    | 'PulseProtocol'
    | 'DocumentLibraryTaa'
    | 'DocumentLibraryResume'
    | 'DocumentLibraryCwo'
    | 'DocumentLibraryFullApplication'
    | 'GuardianI9'
    | 'SspProtocol';

export type PadiDocumentDTO = {
    resetId: string;
    retentionType: string;
    name: string;
    isResettable: boolean;
    status: Status;
    documentTypeId: number;
    documentType: PadiDocumentType;
    fileName: string;
    isGlobal: boolean;
    requirementTypeId: number;
    requirementType: PadiRequirementType;
    createdDateTime: Date;
    isSensitive: boolean;
    storageId: string;
    blobDocumentTypeId: number;
    latestDocumentUri: string;
    latestDocumentCreatedDate: Date;
    isDownloadable: boolean;
    tag: string;
    dealNumber?: string;
    startDate: Date;
    documentHasHistory: boolean;
    documentHistory: HistoricalDocumentDTO[];
    formFields: RequirementFormField[];
};

export type PadiDocumentDTOExtended = PadiDocumentDTO & { taskProps: ITaskCardPropsExtended | null };

export type DocumentUploadDTO = {
    file?: File;
    fields?: string;
    description?: string;
};

type TravelerContactDTO = {
    contactId: string;
    email: string;
    lastName: string;
    firstName: string;
    homePhone: string;
    mobilePhone: string;
    alternatePhone: string;
    isPrimary: boolean;
    relationshipType: string;
};

export type TravelerProfileDTO = {
    primaryContact: TravelerContactDTO;
    secondaryContact: TravelerContactDTO;
    ccId: string;
    ccName: string;
    cManagerId: string;
    cManagerName: string;
    ccEmail: string;
    title: string;
    titleId: number;
    primarySkillId: string;
    wantsCall: boolean;
    willFloat: boolean;
    shiftPreference: number;
    contactPreference: number;
    statesPreference: string;
    preferredFirstName: string;
    shiftLengthPreference: number;
    wantsStrikeNotifications: boolean;
    licenses: string;
    avatarUri: string;
    certifications: string;
    hasCompactLicense: boolean;
    compactLicenseHomeState: string;
    guardianI9Link: string;
    guardianI9Key: number;
    guardianEmpKey: number;
    guardianExpiredDate: Date;
    aboutMe: string;
    tShirtSize: string;
    favouritePlaces: string;
    favouritePetsAndNames: string;
    favouriteHobbies: string;
    favouriteFoodsOrSnacks: string;
    favouriteBeverages: string;
    favouriteBandsOrMusic: string;
    favouriteBooksOrMagazines: string;
    hasUltiProAccess: boolean;
    hasOktaAccess: boolean;
    contactType: string;
    travelerId: string;
    ssn: string;
    dateOfBirth: string;
    isPermTaxUpdating: boolean;
    email: string;
    lastName: string;
    firstName: string;
    middleName: string;
    mobilePhone: string;
    currentAddress1: string;
    currentAddress2: string;
    currentAddress3: string;
    currentAddressCity: string;
    currentAddressState: string;
    currentAddressPostalCode: string;
    permanentAddress1: string;
    permanentAddress2: string;
    permanentAddress3: string;
    permanentAddressCity: string;
    permanentAddressState: string;
    permanentAddressPostalCode: string;
};

export type CertificationOptionDto = {
    id: string;
    name: string;
    value: string;
};

type ShiftItemDto = {
    name: string;
    value: number;
    sortOrder: number;
};

export type StateDto = {
    id: string;
    name: string;
    value: string;
};

type ContactMethodDto = {
    name: string;
    value: number;
    sortOrder: number;
};

export interface IProfileMetadataDTO {
    certifications: CertificationOptionDto[];
    shifts: ShiftItemDto[];
    shiftLengths: ShiftItemDto[];
    states: StateDto[];
    contactMethods: ContactMethodDto[];
    compactStates: string[];
}
