import React from 'react';
import { AppContextProvider } from './contexts';
import { AppRoutes } from './routing';
import './styles/App.css';
import styled from 'styled-components';
import { ActionBar } from './components/ActionBar';
import { Modal } from './components/Modal';
import { PadiDrawer } from './components/PadiDrawer';

const StyledApp = styled.div`
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        'action-bar'
        'main';
`;

const StyledMain = styled.div`
    grid-area: main;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        'actionbar'
        'content';
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

const StyledAppRoutes = styled(AppRoutes)`
    grid-area: content;
    padding: ${(props) => `${props.theme.spacing(6)}`};

    @media (${(props) => props.theme.breakpoints.sm}) {
        padding: ${(props) => props.theme.spacing(8)};
        width: 100%;
        margin: 0 auto;
        max-width: 1650px;
    }
`;

function App() {
    return (
        <AppContextProvider>
            <StyledApp>
                <ActionBar />
                <StyledMain>
                    <StyledAppRoutes />
                </StyledMain>
                <PadiDrawer />
                <Modal />
            </StyledApp>
        </AppContextProvider>
    );
}

export default App;
