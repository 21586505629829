import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from '../pages/home';
import { Documents } from '../pages/documents';
import { useMicrosoftGraphUserGroups } from '../queries/useMicrosoftGraphUserGroups';
import { useAdminMode, useAdminModeSelectors } from '../hooks/useAdminMode';

export const AppRoutes = React.memo((props: { className?: string }) => {
    const { hasAdminPermission } = useMicrosoftGraphUserGroups();
    const setIsAdminMode = useAdminMode(useAdminModeSelectors.setIsAdminMode);

    // set admin mode based on whether or not the user (CC) has appropriate permissions
    React.useEffect(() => {
        setIsAdminMode(hasAdminPermission);
    }, [hasAdminPermission, setIsAdminMode]);

    return (
        <div {...props}>
            <Routes>
                <Route index element={<Home />} />
                <Route path={'/:travelerId'}>
                    <Route index element={<Home />} />
                    <Route path={'docs'} element={<Documents />} />
                </Route>
            </Routes>
        </div>
    );
});
