import React from 'react';
import styled from 'styled-components';

type Size = 'xs' | 'sm' | 'md' | 'lg';

function generateSpacing(spacing: any, size: Size) {
    if (size === 'xs') {
        return `${spacing(2)} 0px`;
    }

    if (size === 'sm') {
        return `${spacing(4)} 0px`;
    }

    if (size === 'md') {
        return `${spacing(6)} 0px`;
    }

    if (size === 'lg') {
        return `${spacing(8)} 0px`;
    }
}

const StyledHr = styled.hr<{ size: Size }>`
    margin: ${(props) => generateSpacing(props.theme.spacing, props.size)};
    color: ${(props) => props.theme.colors.Concrete};
    border: 0;
    border-top: ${(props) => `1px solid ${props.theme.colors.Concrete}`};
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
`;

export function Hr({ size = 'md' }: { size?: Size }) {
    return <StyledHr size={size} />;
}
