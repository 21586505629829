import React from 'react';
import { IButtonProps, MedSolButton } from '@ms/medsol-components';
import { Spinner } from '../Spinner';
import styled from 'styled-components';

export interface ButtonProps extends IButtonProps {
    as?: React.ElementType;
    to?: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    isLoading?: boolean;
}

const StyledSpinner = styled(Spinner)`
    height: 24px;
    width: 24px;

    div {
        height: 24px;
        width: 24px;
        margin: 0;
    }
`;

export function Button({ children, disabled = false, variant = 'default', isLoading = false, ...rest }: ButtonProps) {
    return (
        <MedSolButton variant={variant} disabled={disabled || isLoading} {...rest}>
            {isLoading ? <StyledSpinner variant={variant === 'primary' || variant === 'secondary' ? 'light' : 'dark'} size="sm" thickness="4px" /> : children}
        </MedSolButton>
    );
}
