import { useQuery } from 'react-query';
import { useApi } from '../contexts/api-context';

export function useMicrosoftGraphUserInfo() {
    const { GraphAPI } = useApi();

    const { data, isLoading, error, status } = useQuery(
        'graphUserInfo',
        async () => {
            if (GraphAPI) {
                return await GraphAPI.getUserInfo();
            }
        },
        {
            enabled: !!GraphAPI,
        }
    );

    return { data, isLoading, error, status };
}
