import styled from 'styled-components';
import { MedSolIcon } from '@ms/medsol-components';
import { useAdminMode, useAdminModeSelectors } from '../../hooks/useAdminMode';
import { ReactComponent as PadiLogo } from '../../padi-logo.svg';

const StyledActionBar = styled.div`
    position: sticky;
    top: 0;
    height: 58px;
    width: 100%;
    grid-area: action-bar;
    background-color: ${(props) => props.theme.colors.SincereSlate};
    display: grid;
    color: ${(props) => props.theme.colors.White};
    font-weight: 500;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    justify-items: center;
    padding: ${(props) => `0 ${props.theme.spacing(8)}`};
`;

const StyledActionBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1600px;
`;

const StyledLogoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLogoBar = styled.span`
    font-size: 1.2em;
    font-weight: 300;
    color: ${(props) => props.theme.colors.Blue};
    margin: 0 10px;
`;
const StyleLogoTitle = styled.h4`
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
`;

const StyledAdminIcon = styled(MedSolIcon)`
    fill: ${(props) => props.theme.colors.White};
    font-size: 20px;
    align-self: flex-end;
`;

const StyledModeDisplay = styled.h5`
    display: none;
    @media (${(props) => props.theme.breakpoints.sm}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100px;
        height: 22px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        letter-spacing: 2px;
        ${(props) => `0 ${props.theme.spacing(6)}`};
    }
    color: ${(props) => props.theme.colors.White};
`;

export function ActionBar() {
    const isAdminMode = useAdminMode(useAdminModeSelectors.isAdminMode);
    // const { travelerFullName } = useTravelerProfile();

    return (
        <StyledActionBar>
            <StyledActionBarContainer>
                <StyledLogoWrapper>
                    <PadiLogo />
                    <StyledLogoBar>|</StyledLogoBar>
                    <StyleLogoTitle>PADI</StyleLogoTitle>
                </StyledLogoWrapper>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isAdminMode && (
                        <StyledModeDisplay>
                            <StyledAdminIcon name={'SupervisorAccount'} />
                            <span style={{ marginRight: '16px' }}>Admin</span>
                        </StyledModeDisplay>
                    )}
                </div>
            </StyledActionBarContainer>
        </StyledActionBar>
    );
}
