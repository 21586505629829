import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div<{ progressDisplayTextSize: number; progressDescriptionTextSize: number }>`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: unset;
        .svg-circle-bg {
            fill: none;
        }

        .svg-circle {
            fill: none;
        }
        .svg-circle-text {
            font-size: ${(props) => `${props.progressDisplayTextSize}px`};
            text-align: center;
            text-anchor: middle;
            /* Sincere Slate */

            color: #263349;
        }

        .svg-circle-text-desc {
            font-size: ${(props) => `${props.progressDescriptionTextSize}px`};
            font-weight: 600;
            color: #263349;
            text-align: center;
            text-anchor: middle;
        }
    }
`;
type ColorVariant = 'orange' | 'blue';

function getVariantGradient(variant: ColorVariant) {
    switch (variant) {
        case 'orange': {
            return (
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#9b51e0" />
                    <stop offset="100%" stopColor="#f68d15" />
                </linearGradient>
            );
        }
        case 'blue': {
            return (
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#1da800" />
                    <stop offset="100%" stopColor="#00bbb0" />
                </linearGradient>
            );
        }
    }
}

interface IProgressCircleProps {
    size: number;
    progress: number;
    strokeWidth: number;
    progressDisplay: string;
    progressDisplayTextSize?: number;
    progressDescription: string;
    progressDescriptionTextSize?: number;
    variant?: ColorVariant;
}

export function ProgressCircle({
    size,
    progress,
    strokeWidth,
    progressDisplay,
    progressDescription,
    progressDisplayTextSize = 56,
    progressDescriptionTextSize = 14,
    variant = 'orange',
}: IProgressCircleProps) {
    const [offset, setOffset] = useState(0);
    const circleRef = useRef(null);
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        const progressOffset = ((100 - progress) / 100) * circumference;
        setOffset(progressOffset);
        // @ts-ignore
        circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out;';
    }, [setOffset, circumference, progress, offset]);

    return (
        <StyledDiv progressDisplayTextSize={progressDisplayTextSize} progressDescriptionTextSize={progressDescriptionTextSize}>
            <svg height={size} width={size}>
                <defs>{getVariantGradient(variant)}</defs>
                <circle className="svg-circle-bg" stroke="#F4F5F6" cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
                <circle
                    className="svg-circle"
                    stroke="url(#gradient)"
                    ref={circleRef}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    transform={`rotate(-270 ${center} ${center})`}
                />
                <text x={`${center}`} y={`${center + 10} `} className="svg-circle-text">
                    {progressDisplay}
                </text>
            </svg>
        </StyledDiv>
    );
}
