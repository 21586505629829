// this hook takes a function which is invoked when the user clicks outside your component,
//and returns a ref which you need to refer to your Content Element
import { useCallback, useEffect, useRef } from 'react';

export function useClickOutsideOrEscListenerRef(onClose: () => void) {
    const ref = useRef(null);
    const escapeListener = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );
    const clickListener = useCallback(
        (e: MouseEvent) => {
            if (!(ref.current! as any).contains(e.target)) {
                onClose?.();
            }
        },
        [onClose]
    );
    useEffect(() => {
        document.addEventListener('click', clickListener);
        document.addEventListener('keyup', escapeListener);
        return () => {
            document.removeEventListener('click', clickListener);
            document.removeEventListener('keyup', escapeListener);
        };
    }, [clickListener, escapeListener]);
    return ref;
}
