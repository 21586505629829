import React from 'react';
import { useQuery, QueryStatus } from 'react-query';
import { useApi } from '../contexts/api-context';
import { Group } from '@microsoft/microsoft-graph-types';
import { doesUserHaveAdminPermission, doesUserHaveCMPermission, doesUserHaveDeletePermission } from '../utilities/microsoft-graph-utilities';

type TQueryResponse = {
    ['@odata.context']: string;
    value: Group[];
};

type TUseMicrosoftGraphUserGroups = {
    groups: Group[];
    hasAdminPermission: boolean;
    hasCMADPermission: boolean;
    hasDeletePermission: boolean;
    status: QueryStatus;
};

export function useMicrosoftGraphUserGroups(): TUseMicrosoftGraphUserGroups {
    const { GraphAPI } = useApi();

    const { data, status } = useQuery<TQueryResponse, Error>(
        'graphUserGroups',
        async () => {
            if (GraphAPI) {
                return await GraphAPI.getUserGroupsMemberOf();
            }
        },
        {
            enabled: !!GraphAPI,
        }
    );

    return React.useMemo(() => {
        const groupNames = data?.value?.map((group: Group) => group.displayName as string) || [];

        return {
            groups: data?.value || [],
            hasAdminPermission: doesUserHaveAdminPermission(groupNames),
            hasCMADPermission: doesUserHaveCMPermission(groupNames),
            hasDeletePermission: doesUserHaveDeletePermission(groupNames),
            status,
        };
    }, [data, status]);
}
