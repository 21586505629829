import { IBaseAPI } from '.';
import { PadiEndpoints } from '../constants/api-constants';
import {
    generateDocumentsAPIWithTravelerId,
    generateFullApplicationUploadAPI,
    generateProfileAPIWithTravelerId,
    generateRequirementHistoryAPIWithTravelerIdAndRequirementId,
    generateDeleteDocumentAPIWithTravelerIdAndDocumentId,
    generateResetDocumentAPIWithTravelerIdAndRequirementId,
    generatePadiDocumentsAPIWithTravelerId,
    generateRequirementUploadAPIUrl,
    generateSpeicalDocumentUploadAPIUrl,
    generateCertificationURL,
    generateLicenseURL,
    generateAuditLogAPIUrl,
} from '../utilities/app-utilities';
import { AuditLogActions, DocumentUploadDTO, IProfileMetadataDTO, TravelerProfileDTO } from '../models/app-types';
import { ICertificationModel } from '@ms/credentials/dist/models/certification.models';
import { ILicenseModel } from '@ms/credentials/dist/models/license.models';
import { UploadCertificationPayload } from '../features/credentials/certifications/types';
import { UploadLicensePayload } from '../features/credentials/licenses/types';
import { CertificationLogic } from '../features/credentials/certifications/logic';
import { LicenseLogic } from '../features/credentials/licenses/logic';

interface UploadFullApplicationPayload {
    travelerId: string;
    payload: {
        file: File;
    };
}

interface UploadRequirementPayload {
    travelerId: string;
    requirementId: string;
    payload: DocumentUploadDTO;
}

interface UploadSpecialDocumentRequirementPayload {
    travelerId: string;
    payload: DocumentUploadDTO;
}

export interface SendAuditLogPayload {
    action?: AuditLogActions;
    fileName?: string;
    documentName?: string;
    documentId: string;
    assignmentId?: string;
    requirementId?: string;
}
export interface IPadiService {
    getTravelerDocuments: (travelerId?: string) => Promise<any>;
    getTravelerProfile: (travelerId?: string) => Promise<TravelerProfileDTO>;
    deleteDocument: ({ travelerId, documentId, deleteReason }: { travelerId: string; documentId: string; deleteReason: number }) => Promise<any>;
    resetDocument: ({ travelerId, requirementId }: { travelerId: string; requirementId: string }) => Promise<any>;
    uploadFullApplication: ({ travelerId, payload }: UploadFullApplicationPayload) => Promise<any>;
    getRequirementHistoryUris: (travelerId: string, requirementId: string) => Promise<any>;
    sendAuditLog: (payload: SendAuditLogPayload[]) => Promise<any>;
    downloadRequirements: (payload: any) => Promise<any>;
    getPadiDocuments: (travelerId?: string) => Promise<any>;
    uploadRequirement: (payload: UploadRequirementPayload) => Promise<any>;
    uploadSpecialDocumentRequirement: (payload: UploadSpecialDocumentRequirementPayload) => Promise<void>;
    uploadCertification: (payload: UploadCertificationPayload) => Promise<void>;
    uploadLicense: (payload: UploadLicensePayload) => Promise<void>;
    getProfileMetaData: () => Promise<IProfileMetadataDTO>;
    getCertifications: (travelerId?: string) => Promise<ICertificationModel[]>;
    getLicenses: (travelerId?: string) => Promise<ILicenseModel[]>;
    getCertification: (travelerId: string, certificationId: string) => Promise<ICertificationModel>;
    getLicense: (travelerId: string, licenseId: string) => Promise<ILicenseModel>;
}

export function PadiService(api: IBaseAPI): IPadiService {
    async function getTravelerDocuments(travelerId?: string): Promise<any> {
        return api.get<any>(generateDocumentsAPIWithTravelerId(travelerId));
    }

    async function getTravelerProfile(travelerId?: string): Promise<TravelerProfileDTO> {
        return api.get<any>(generateProfileAPIWithTravelerId(travelerId));
    }

    async function deleteDocument({ travelerId, documentId, deleteReason }: { travelerId: string; documentId: string; deleteReason: number }): Promise<any> {
        return api.remove(generateDeleteDocumentAPIWithTravelerIdAndDocumentId(travelerId, documentId, deleteReason));
    }

    async function resetDocument({ travelerId, requirementId }: { travelerId: string; requirementId: string }): Promise<any> {
        return api.post(generateResetDocumentAPIWithTravelerIdAndRequirementId(travelerId, requirementId), {});
    }

    async function uploadFullApplication({ travelerId, payload }: UploadFullApplicationPayload): Promise<any> {
        const { file } = payload;
        const formData = new FormData();
        formData.append('File', file as File);

        return api.upload(generateFullApplicationUploadAPI(travelerId), formData);
    }

    async function getRequirementHistoryUris(travelerId: string, requirementId: string): Promise<any> {
        return api.get<any>(generateRequirementHistoryAPIWithTravelerIdAndRequirementId(travelerId, requirementId));
    }

    async function sendAuditLog(payload: SendAuditLogPayload[]): Promise<any> {
        return api.post(generateAuditLogAPIUrl(), payload);
    }

    async function downloadRequirements(payload: any): Promise<any> {
        return api.postForFiles(PadiEndpoints.downloadRequirements, payload);
    }

    async function getPadiDocuments(travelerId?: string): Promise<any> {
        return api.get<any>(generatePadiDocumentsAPIWithTravelerId(travelerId));
    }

    async function uploadRequirement({ travelerId, requirementId, payload }: UploadRequirementPayload) {
        const { file, fields, description } = payload;
        const formData = new FormData();

        if (file) {
            formData.append('File', file);
        }

        if (fields) {
            formData.append('Fields', fields);
        }

        if (description) {
            formData.append('Description', description);
        }

        return api.upload(generateRequirementUploadAPIUrl(travelerId, requirementId), formData);
    }

    async function uploadSpecialDocumentRequirement({ travelerId, payload }: UploadSpecialDocumentRequirementPayload): Promise<void> {
        const { file, description } = payload;
        const formData = new FormData();

        if (file) {
            formData.append('File', file);
        }

        if (travelerId) {
            formData.append('id', travelerId);
        }

        if (description) {
            formData.append('description', description);
        }

        return api.upload(generateSpeicalDocumentUploadAPIUrl(), formData);
    }

    async function getCertifications(travelerId?: string): Promise<ICertificationModel[]> {
        return api.get(generateCertificationURL(travelerId));
    }

    async function getCertification(travelerId: string, certificationId: string): Promise<ICertificationModel> {
        return api.get(generateCertificationURL(travelerId, certificationId));
    }

    async function uploadCertification({ travelerId, payload }: UploadCertificationPayload): Promise<void> {
        const formData = CertificationLogic.getCertificationFormData(payload);
        return api.upload(generateCertificationURL(travelerId), formData);
    }

    async function getLicenses(travelerId?: string): Promise<ILicenseModel[]> {
        return api.get(generateLicenseURL(travelerId));
    }

    async function getLicense(travelerId: string, licenseId: string): Promise<ILicenseModel> {
        return api.get(generateLicenseURL(travelerId, licenseId));
    }

    async function uploadLicense({ travelerId, payload }: UploadLicensePayload): Promise<void> {
        const formData = LicenseLogic.getLicenseFormData(payload);
        return api.upload(generateLicenseURL(travelerId), formData);
    }

    async function getProfileMetaData(): Promise<IProfileMetadataDTO> {
        return api.get(PadiEndpoints.metaData);
    }

    return {
        getTravelerDocuments,
        getTravelerProfile,
        deleteDocument,
        resetDocument,
        uploadFullApplication,
        getRequirementHistoryUris,
        sendAuditLog,
        downloadRequirements,
        getPadiDocuments,
        uploadRequirement,
        uploadSpecialDocumentRequirement,
        uploadCertification,
        uploadLicense,
        getProfileMetaData,
        getCertifications,
        getLicenses,
        getCertification,
        getLicense,
    };
}
