import React from 'react';
import { MedSolSpace } from '@ms/medsol-components';
import { TaskCardDescriptionWrapper, StyledStatusDesc } from '../components/TaskCard/task-card-description-container';
import { PadiDocumentDTO } from '../models/app-types';

export function generateDocumentDescription(node: React.ReactNode): React.ReactElement {
    return <>{node}</>;
}

export function generatePADIDocumentDescription(document: PadiDocumentDTO): React.ReactNode {
    const { name = '', dealNumber, startDate, isGlobal, retentionType } = document;
    const dealNumberAndStartDateMarkupForAssignmentDocs = (
        <TaskCardDescriptionWrapper>
            {dealNumber && <StyledStatusDesc>{`Deal ID: ${dealNumber}`}</StyledStatusDesc>}
            {dealNumber && <MedSolSpace w={2} />}
            {startDate && <StyledStatusDesc>{`Start Date: ${startDate}`}</StyledStatusDesc>}
        </TaskCardDescriptionWrapper>
    );
    const dealNumberAndStartDateMarkupForGlobalDocs = (
        <TaskCardDescriptionWrapper>
            <StyledStatusDesc>Deal ID: 'N/A'</StyledStatusDesc>
            <MedSolSpace w={2} />
            <StyledStatusDesc>Start Date: 'N/A' </StyledStatusDesc>
        </TaskCardDescriptionWrapper>
    );
    const permTaxFormForRetentionTypeExtensionMarkup = (
        <TaskCardDescriptionWrapper>
            {dealNumber && <StyledStatusDesc>{`Deal ID: ${dealNumber}`}</StyledStatusDesc>}
            {dealNumber && <MedSolSpace w={2} />}
            {startDate && <StyledStatusDesc>{`Start Date: ${startDate}`}</StyledStatusDesc>}
            {dealNumber && <MedSolSpace w={2} />}
            <StyledStatusDesc>Extension</StyledStatusDesc>
        </TaskCardDescriptionWrapper>
    );
    //show deal id and start date if the doc is perm tax card and related to assignment and add extension tag if its a retentionType Extension else show N/A if its global
    if (name?.split(' ').join('').toLocaleLowerCase() === 'permanenttaxresidentnotification') {
        if (!isGlobal && startDate) {
            if (retentionType?.toLocaleLowerCase() === 'extension') {
                return permTaxFormForRetentionTypeExtensionMarkup;
            } else {
                return dealNumberAndStartDateMarkupForAssignmentDocs;
            }
        } else if (isGlobal) {
            return dealNumberAndStartDateMarkupForGlobalDocs;
        }
    }

    return <></>;
}
