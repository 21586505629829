import { MedSolSpace } from '@ms/medsol-components';
import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
`;

interface ILoadingContainerProps {
    isLoading?: boolean;
    loadingText?: string;
    children: React.ReactNode;
}

export function LoadingContainer({ isLoading, children, loadingText }: ILoadingContainerProps) {
    if (isLoading) {
        if (loadingText) {
            return (
                <StyledContainer>
                    <Spinner />
                    <MedSolSpace h={4} />
                    <p>{loadingText}</p>
                </StyledContainer>
            );
        }

        return <Spinner />;
    }

    return <>{children}</>;
}
