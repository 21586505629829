import styled from 'styled-components';

// Styling for search and sort by filters

export const StyledFiltersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 720px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }
`;

export const StyledSearchInputContainer = styled.div`
    width: 100%;
    @media (${(props) => props.theme.breakpoints.sm}) {
        max-width: 344px;
    }
`;

export const StyledHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${(props) => props.theme.spacing(4)};

    @media (${(props) => props.theme.breakpoints.sm}) {
        grid-template-columns: 1fr;
    }
`;

export const StyledSection = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-rows: auto auto;

    @media (${(props) => props.theme.breakpoints.sm}) {
        grid-template-columns: 2fr max-content;
    }
`;

export const StyledButtonAndProgressCirContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    //justify-content: flex-end;
    align-items: center;
    justify-content: flex-start;

    @media (${(props) => props.theme.breakpoints.sm}) {
        justify-content: flex-end;
        align-items: center;
        min-width: 500px;
    }
`;

export const StyledDocumentListContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;
