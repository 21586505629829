import React from 'react';
import { MedSolSpace, Typography } from '@ms/medsol-components';
import styled from 'styled-components';
import { AuditLogActions, HistoricalDocumentDTO, PadiDocumentDTOExtended, Status } from '../../models/app-types';
import { formatDate } from '../../utilities/app-utilities';
import { LinkButton } from '../LinkButton';
import { DateTime } from 'luxon';
import { Hr } from '../Hr';
import { useAuditLogAction } from '../../queries/useAuditLogAction';
import { SendAuditLogPayload } from '../../services/PadiService';

const StyledHistoryContainer = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

const StyledHistoryContent = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;

    ul {
        width: 100%;
    }
`;

const StyledHistoryRowTitle = styled.span`
    font-weight: 500;
`;

const StyledHistoryRow = styled.li`
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    width: 100%;
    font-size: 14px;
`;

const StyledLinkButton = styled(LinkButton)`
    &&& {
        padding: 0;
    }
`;

export function DocumentHistoryPanel({ document }: { document?: PadiDocumentDTOExtended }) {
    if (!document) return null;

    if (document.requirementType === 'GuardianI9') {
        if (document.taskProps?.status === Status.Pending || document.taskProps?.status === Status.Action) {
            return <p>I9 Pending</p>;
        }
    }

    return (
        <StyledHistoryContainer>
            <StyledHistoryContent>
                <ul>
                    <StyledHistoryRow>
                        <span>History</span>
                        <span>Date</span>
                    </StyledHistoryRow>
                    <AuditTrail document={document} />
                </ul>
                <MedSolSpace h={2} />
                <Hr size="sm" />
            </StyledHistoryContent>
        </StyledHistoryContainer>
    );
}
// currently only displays records of completed documents but will eventually grow to different types of audit records
function AuditTrail({ document }: { document: PadiDocumentDTOExtended }) {
    const { sendAuditLogAction } = useAuditLogAction();
    const audits: HistoricalDocumentDTO[] = document.taskProps?.documentHistory || [];
    return (
        <>
            {audits && audits.length > 0 ? (
                audits.map((history, index) => {
                    return (
                        <StyledHistoryRow key={history.uri}>
                            <StyledLinkButton
                                size="small"
                                onClick={() => {
                                    const documentId = history.documentId ? history.documentId : '';
                                    if (documentId) {
                                        const auditLogInitialPayload: SendAuditLogPayload = {
                                            fileName: document.fileName,
                                            documentName: document.name,
                                            requirementId: document.resetId,
                                            documentId: documentId,
                                        };
                                        //only send audit log if document Id exists.
                                        const auditLogPayload: SendAuditLogPayload[] = [{ ...auditLogInitialPayload, action: AuditLogActions.Viewed }]; //added action here because we may have other actions in the future.
                                        sendAuditLogAction(auditLogPayload); // this call will let the BE know that user has viewed the document.
                                    }
                                    window.open(history.uri);
                                }}
                            >
                                View Document
                            </StyledLinkButton>
                            <StyledHistoryRowTitle>{formatDate(history.createdDateTime, DateTime.DATETIME_SHORT)}</StyledHistoryRowTitle>
                        </StyledHistoryRow>
                    );
                })
            ) : (
                <Typography variant="p1" color="Gray_500">
                    No Document History
                </Typography>
            )}
        </>
    );
}
