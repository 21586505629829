import { MedSolIcon, Typography, MedSolTheme, MedSolSpace } from '@ms/medsol-components';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function FeatureIndicator({ isActive = false, displayText = '' }: { isActive?: boolean; displayText?: string }) {
    return (
        <StyledContainer>
            <MedSolIcon
                fontSize="18"
                name={isActive ? 'Check' : 'NotificationCircle'}
                color={isActive ? MedSolTheme.colors.Success : MedSolTheme.colors.Error}
            />
            <MedSolSpace w={2} />
            <Typography variant="p2" color="Gray_500">
                {displayText}
            </Typography>
        </StyledContainer>
    );
}
