import React from 'react';
import { Formik, Form } from 'formik';
import { MedSolSpace, ModalV2Body, ModalV2Header, ModalV2Footer, Typography } from '@ms/medsol-components';
import { FileSelector } from '../FileSelector';
import { mapRequirementFormValuesForUpload } from '../../utilities/app-utilities';
import { RequirementField } from '../RequirementField';
import { MedSolStatusMessage } from '../MedSolStatusMessage';
import { useUploadRequirement, IRequirementFormValues } from '../../hooks/useUploadRequirement';
import { ButtonPrimary } from '../ButtonPrimary';
import { PadiDocumentDTOExtended } from '../../models/app-types';
import { useModal } from '../../hooks/useModal';

export interface IModalUploadRequirementProps {
    selectedDocument?: PadiDocumentDTOExtended;
}

export function ModalUploadRequirement({ selectedDocument }: IModalUploadRequirementProps) {
    const { formFields, initialValues, requirementTitle, requirementId, travelerId, uploadRequirementMutation, validationSchema } =
        useUploadRequirement(selectedDocument);
    const { closeModal } = useModal();

    return (
        <>
            <ModalV2Header hasDivider={false} onClose={closeModal}>
                <Typography variant="head4_strong">{requirementTitle}</Typography>
            </ModalV2Header>
            <ModalV2Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values: IRequirementFormValues) => {
                        const { file, ...rest } = values; // split the file from the rest of the form fields
                        const formValues = { ...rest };

                        const payload = {
                            file,
                            fields: JSON.stringify(mapRequirementFormValuesForUpload(formFields, formValues)),
                        };

                        //@ts-ignore - type issue with file potentially being null which validate function should be catching
                        uploadRequirementMutation.mutate({ travelerId, requirementId, payload });
                    }}
                >
                    {({ values, errors, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
                        <Form id="MyForm" onSubmit={handleSubmit}>
                            {uploadRequirementMutation.status === 'idle' && (
                                <>
                                    {formFields.map((formField) => (
                                        <>
                                            <RequirementField {...formField} error={errors[formField.name] as string} />
                                            <MedSolSpace h={4} />
                                        </>
                                    ))}
                                    <FileSelector file={values.file} setFile={(file) => setFieldValue('file', file)} useDetails />
                                    <MedSolStatusMessage status="error" text={errors.file} />
                                    <MedSolSpace h={4} />
                                </>
                            )}
                            <ModalV2Footer>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <ButtonPrimary type="submit" disabled={isSubmitting || !isValid}>
                                        Upload
                                    </ButtonPrimary>
                                </div>
                            </ModalV2Footer>
                        </Form>
                    )}
                </Formik>
            </ModalV2Body>
        </>
    );
}
