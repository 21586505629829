// create React component
import React, { useState } from 'react';
import { Typography, MedSolSpace, MedSolButton, ModalV2Body, ModalV2Footer, ModalV2Header, MedSolSpinner, colors } from '@ms/medsol-components';
import { DELETE_DOCUMENT_REASON } from '../../models/app-types';
import styled from 'styled-components';
import { useModal } from '../../hooks/useModal';

export interface IModalDeleteDocumentProps {
    documentId: string;
    travelerId: string;
    deleteDocumentMutation: any;
}

const StyledModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const StyledRadioContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
`;

const StyledRadioSelection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
`;

const StyledRadioButton = styled.input`
    height: 24px;
    width: 24px;
    left: 0px;
    top: 0px;
    border-radius: 15px;
`;

const StyledRadioButtonLabel = styled.label`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: inherit;
`;

export function ModalDeleteDocument({ documentId, travelerId, deleteDocumentMutation }: IModalDeleteDocumentProps) {
    const [deleteReason, setDeleteReason] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const { closeModal } = useModal();

    return (
        <>
            <ModalV2Header hasDivider={false} onClose={closeModal}>
                <Typography variant="head4_strong">Confirm deletion</Typography>
            </ModalV2Header>
            <ModalV2Body>
                <Typography variant="p1">Once you delete this file you will no longer have access to it. What is the reason for deletion?</Typography>
                <MedSolSpace h="8px" />
                <StyledRadioContainer>
                    <StyledRadioSelection>
                        <StyledRadioButton
                            disabled={showSpinner}
                            type="radio"
                            id="WrongTraveler"
                            name="WrongTraveler"
                            checked={deleteReason === DELETE_DOCUMENT_REASON.WrongTraveler}
                            onChange={() => setDeleteReason(DELETE_DOCUMENT_REASON.WrongTraveler)}
                        />
                        <StyledRadioButtonLabel htmlFor="WrongTraveler">Uploaded to the incorrect traveler's account</StyledRadioButtonLabel>
                    </StyledRadioSelection>
                    <StyledRadioSelection>
                        <StyledRadioButton
                            disabled={showSpinner}
                            type="radio"
                            id="WrongAsset"
                            name="WrongAsset"
                            checked={deleteReason === DELETE_DOCUMENT_REASON.WrongAsset}
                            onChange={() => setDeleteReason(DELETE_DOCUMENT_REASON.WrongAsset)}
                        />
                        <StyledRadioButtonLabel htmlFor="WrongAsset">Uploaded to the incorrect document requirement</StyledRadioButtonLabel>
                    </StyledRadioSelection>
                </StyledRadioContainer>
            </ModalV2Body>
            <ModalV2Footer hasDivider={false}>
                <StyledModalFooter>
                    <MedSolButton
                        disabled={showSpinner}
                        variant="secondary"
                        onClick={function () {
                            closeModal();
                        }}
                    >
                        Keep
                    </MedSolButton>
                    {showSpinner ? (
                        <div style={{ paddingRight: '40px' }}>
                            <MedSolSpinner size="sm" color={colors.SincereSlate} thickness={'6px'} />
                        </div>
                    ) : (
                        <MedSolButton
                            variant="primary"
                            disabled={deleteReason === 0}
                            style={{ opacity: deleteReason === 0 ? 0.5 : 1 }}
                            onClick={() => {
                                deleteDocumentMutation.mutate({ travelerId, documentId, deleteReason });
                                setShowSpinner(true);
                            }}
                        >
                            Delete
                        </MedSolButton>
                    )}
                </StyledModalFooter>
            </ModalV2Footer>
        </>
    );
}
