import styled from 'styled-components';
import { MedSolIcon, MedSolIconOptions, TIconOptions } from '@ms/medsol-components';

const StyledTaskCardAction = styled.span`
    grid-area: action;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #263349;

    .action-text {
        margin-right: 8px;
        margin-top: 2px;
        display: none;

        @media (${(props) => props.theme.breakpoints.sm}) {
            display: flex;
        }
    }
`;

const TaskCardActionIcon = styled(MedSolIcon)`
    fill: ${(props) => props.theme.colors.Onyx};
`;

interface ITaskCardActionProps {
    actionText?: string;
    iconName?: TIconOptions;
}

export function TaskCardAction({ actionText = 'View', iconName = MedSolIconOptions.ArrowRight }: ITaskCardActionProps) {
    return (
        <StyledTaskCardAction>
            <span className="action-text">{actionText}</span>
            <TaskCardActionIcon name={iconName} />
        </StyledTaskCardAction>
    );
}
