enum ROUTING_ROUTES {
    home = '/:travelerId?',
    documents = '/:travelerId/docs',
    document = '/:travelerId/document/:documentId',
    profile = '/:travelerId/profile',
    unexpectedError = '/oops',
    inputs = '/inputs',
}

export function generateDocumentsRouteWithTravelerId(travelerId = '') {
    if (!travelerId) return '';

    return ROUTING_ROUTES.documents.replace(':travelerId', travelerId);
}
