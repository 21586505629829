import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';

const StyledTaskCardSelection = styled.div`
    grid-area: icon;
    display: flex;
    //padding: ${(props) => props.theme.spacing(4)};
    align-items: center;
    justify-content: center;
    border-right: ${(props) => `1px solid ${props.theme.colors.Concrete}`};
    height: 100%;
    width: 100%;
`;

interface ITaskCardSelectionProps {
    isSelected?: boolean;
    handleSelect?: () => void;
}

export function TaskCardSelection({ isSelected = false, handleSelect }: ITaskCardSelectionProps) {
    function handleClick(e: SyntheticEvent) {
        e.stopPropagation();
        handleSelect?.();
    }

    return (
        <StyledTaskCardSelection onClick={handleClick}>
            <Checkbox checked={isSelected} handleCheckboxChange={() => {}} />
        </StyledTaskCardSelection>
    );
}
