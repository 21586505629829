import React from 'react';
import { useParams } from 'react-router-dom';
import { MedSolIconButton, MedSolSpace, MedSolTheme } from '@ms/medsol-components';
import {
    StyledFiltersContainer,
    StyledHeader,
    StyledSection,
    StyledSearchInputContainer,
    StyledButtonAndProgressCirContainer,
    StyledDocumentListContainer,
} from './styles';
import { LoadingContainer } from '../../components/LoadingContainer';
import { sortTasksByNameAndStatus } from '../../utilities/app-utilities';
import { DocumentTaskCard } from '../../components/DocumentTaskCard';
import { OrderByDropDown } from '../../components/OrderByDropdown';
import { useTravelerId } from '../../hooks/useTravelerId';
import { useDrawer } from '../../hooks/useDrawer';
import { usePadiDocumentsQuery } from '../../queries/usePadiDocumentsQuery';
import { useDocumentOrderByFilter, useOrderBySelector } from '../../hooks/useDocumentsOrderByFilter';
import { ProgressCircle } from '../../components/ProgressCircle';
import { ITaskCardPropsExtended, Status } from '../../models/app-types';
import { TravelerProfile } from './_/TravelerProfile';
import { useTravelerProfile } from '../../queries/useTravelerProfile';
import { TextInput } from '../../components/TextInput';
import { Hr } from '../../components/Hr';
import { useSearch } from '../../hooks/useSearch';
import { useGetLicensesQuery } from '../../features/credentials/licenses/hooks';
import { useGetCertificationsQuery } from '../../features/credentials/certifications/hooks';

type DocumentsPageParamTypes = {
    travelerId: string | undefined;
};

export function Documents() {
    const { travelerId } = useParams<DocumentsPageParamTypes>(); // get traveler id from route param
    const { setTravelerId } = useTravelerId();
    const { status: travelerProfileStatus } = useTravelerProfile();
    const { openDrawer, openDocumentDetailsDrawer } = useDrawer();

    // globally set traveler id on load
    React.useEffect(() => {
        setTravelerId(travelerId || '');
    }, [travelerId, setTravelerId]);

    // get tasks from all sources
    const { padiDocumentTasks, status: padiDocumentsDataStatus, error } = usePadiDocumentsQuery();
    const { licenseTasks } = useGetLicensesQuery();
    const { certificationTasks } = useGetCertificationsQuery();

    // combine tasks from all sources
    const tasksFromAllSources = [...padiDocumentTasks, ...licenseTasks, ...certificationTasks];

    // setup search
    const { searchValue, setSearchValue, searchedData } = useSearch(tasksFromAllSources, 'title');

    // filter value
    const order = useDocumentOrderByFilter(useOrderBySelector.order);

    // derived data
    const numCompletedDocuments = tasksFromAllSources.filter((doc) => doc.status === Status.Done).length;
    const isPageLoading = padiDocumentsDataStatus === 'idle' || padiDocumentsDataStatus === 'loading' || travelerProfileStatus === 'loading';

    return (
        <div>
            <LoadingContainer isLoading={isPageLoading} loadingText="Preparing traveler information...">
                {error ? (
                    'oops, there was an error. Try again.'
                ) : (
                    <>
                        <StyledHeader>
                            <StyledSection>
                                <TravelerProfile />
                                <StyledButtonAndProgressCirContainer>
                                    <MedSolIconButton
                                        variant="ghost"
                                        icon={{ name: 'Plus', color: MedSolTheme.colors.SincereSlate, fontSize: '14px' }}
                                        onClick={() => {
                                            openDrawer('addDocument');
                                        }}
                                    >
                                        Add Document
                                    </MedSolIconButton>
                                    <MedSolSpace w={4} />
                                    <MedSolIconButton
                                        variant="primary"
                                        icon={{ name: 'Download2', color: MedSolTheme.colors.White, fontSize: '14px' }}
                                        onClick={() => openDrawer('downloadDocuments')}
                                    >
                                        Download
                                    </MedSolIconButton>

                                    <MedSolSpace w={4} />
                                    <ProgressCircle
                                        progress={tasksFromAllSources?.length ? Math.round((numCompletedDocuments / tasksFromAllSources.length) * 100) : 0}
                                        size={100}
                                        strokeWidth={6}
                                        progressDisplay={`${numCompletedDocuments}/${tasksFromAllSources?.length || 0}`}
                                        progressDescription="Documents Completed"
                                        progressDisplayTextSize={24}
                                        variant="blue"
                                    />
                                </StyledButtonAndProgressCirContainer>
                            </StyledSection>
                            <Hr size="xs" />
                            <StyledFiltersContainer>
                                <StyledSearchInputContainer>
                                    <TextInput
                                        name="search"
                                        type="search"
                                        placeholder="Search Documents"
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.currentTarget.value);
                                        }}
                                    />
                                </StyledSearchInputContainer>
                                <MedSolSpace w={6} />
                                <OrderByDropDown />
                            </StyledFiltersContainer>
                            <Hr size="xs" />
                        </StyledHeader>
                        <MedSolSpace h={2} />
                        <StyledDocumentListContainer>
                            <MedSolSpace h={4} />
                            <>
                                <ul>
                                    {searchedData &&
                                        sortTasksByNameAndStatus(searchedData, order).map((task: ITaskCardPropsExtended, index: number) => {
                                            return (
                                                <>
                                                    <DocumentTaskCard
                                                        key={`${index}-${task.title}`}
                                                        {...task}
                                                        onClick={() => {
                                                            if (task.isLocked) return; // do nothing if doc is locked
                                                            openDocumentDetailsDrawer(task);
                                                        }}
                                                    />
                                                    <MedSolSpace h={4} />
                                                </>
                                            );
                                        })}
                                </ul>
                                <MedSolSpace h={3} />
                                {tasksFromAllSources.length > searchedData.length && (
                                    <p style={{ color: MedSolTheme.colors.Silver, fontSize: '14px', display: 'flex', justifyContent: 'center' }}>
                                        More results being hidden by search
                                    </p>
                                )}
                            </>
                        </StyledDocumentListContainer>
                    </>
                )}
            </LoadingContainer>
        </div>
    );
}
