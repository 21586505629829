import React from 'react';
import { useQuery } from 'react-query';
import { generateProfileAPIWithTravelerId } from '../utilities/app-utilities';
import { useApi } from '../contexts/api-context';
import { useTravelerId, useTravelerIdSelectors } from '../hooks/useTravelerId';
import { TravelerProfileDTO } from '../models/app-types';

export function useTravelerProfile() {
    const { PadiAPI } = useApi();
    const travelerId = useTravelerId(useTravelerIdSelectors.travelerId);

    const { data, isLoading, error, status } = useQuery<TravelerProfileDTO | undefined, Error>(
        generateProfileAPIWithTravelerId(travelerId),
        async () => {
            if (PadiAPI) {
                return await PadiAPI.getTravelerProfile(travelerId);
            }
        },
        {
            enabled: !!travelerId,
        }
    );

    const travelerFullName = React.useMemo(() => (data?.firstName && data?.lastName ? `${data?.firstName} ${data?.lastName}` : '...'), [data]);

    return { data, travelerFullName, isLoading, error, status };
}
