import { useMutation } from 'react-query';
import { useApi } from '../contexts/api-context';
import { SendAuditLogPayload } from '../services/PadiService';
import { USE_AUDIT_LOG_INFO } from '../constants/api-constants';

export function useAuditLogAction() {
    const { PadiAPI } = useApi();

    const mutateQuery = useMutation(async (payload: SendAuditLogPayload[]) => PadiAPI?.sendAuditLog(payload), {
        onError: (error) => console.log('Error submitting audit log', error),
    });

    function sendAuditLogAction(payload: SendAuditLogPayload[]) {
        if (USE_AUDIT_LOG_INFO === 'true') {
            return mutateQuery.mutate(payload);
        } else {
            return null;
        }
    }

    return {
        sendAuditLogAction,
    };
}
