import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 100px auto;
`;

export function AuthError({ error }: { error: any }) {
    return (
        <StyledContainer>
            <h6>An Error Occurred: {error.errorCode}</h6>
        </StyledContainer>
    );
}
