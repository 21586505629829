import React from 'react';
import { Button } from '../Button';
import styled from 'styled-components';
import { MedSolSpace, MedSolValidationMessage, Typography } from '@ms/medsol-components';

const MAX_UPLOAD_FILE_SIZE = 31457280;
const MAX_UPLOAD_FILE_SIZE_FRIENDLY_NAME = '30Mb';
const ALLOWED_FILE_TYPES_FOR_UPLOAD = 'pdf,png,jpg,jpeg,doc,docx,bmp';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledDetailsContainer = styled.div`
    display: flex;
    flex-flow: column;
`;

/** Accepts a comma delimited string and replaces comma with pipe */
const generateAllowedFileTypeDisplay = (allowedFileTypes: string) => allowedFileTypes.replace(/,/g, ', ');

/** Create array of accepted file types from comma delimited string */
const generateAllowedFileTypeArray = (allowedFileTypes: string) => allowedFileTypes.toLocaleLowerCase().split(',');

/** Checks file name against allowed file type array to see if it matches */
const isValidFileType = (fileName: string, allowedFileTypes: string[]) => allowedFileTypes.indexOf(fileName.split('.').pop()?.toLocaleLowerCase() || '') > -1;

/** Truncate file name string if its too long */
const truncateFileNameString = (str: string, length: number): string => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= length) {
        return str;
    }

    // Return str truncated with '...' and file type concatenated to the end of str.
    return str.slice(0, length) + '...' + str.split('.').pop();
};

interface FileSelectorProps {
    /** Parent file value */
    file?: File | null;

    /** Parent file setter */
    setFile: (file: File) => void;

    /** Comma delimited string of accepted file types
     * default: 'pdf,png,jpg,doc,docx,bmp' */
    allowedFileTypesForUpload?: string;

    /** File brower display text
     * default: 'Browse Files'
     */
    buttonText?: string;

    /** Error text if necessary */
    errorText?: string;

    /** Maximum allow byte size for file
     * default: 31457280 (30mb)
     */
    maxUploadFileSize?: number;

    /** Human-readable file size display
     * default: '30MB'
     */
    maxUploadFileSizeFriendlyName?: string;

    /** Optional name for file input
     * default: 'uploadFile'
     */
    name?: string;

    /** Maximum number of characters to use to display chosen file name
     * default: 25
     */
    truncateFileNameStringLength?: number;

    /** Whether or not to display internal file name and allowed size/type description
     * default: false
     */
    useDetails?: boolean;
}

export function FileSelector({
    allowedFileTypesForUpload = ALLOWED_FILE_TYPES_FOR_UPLOAD,
    buttonText = 'Browse Files',
    errorText = '',
    file,
    maxUploadFileSize = MAX_UPLOAD_FILE_SIZE,
    maxUploadFileSizeFriendlyName = MAX_UPLOAD_FILE_SIZE_FRIENDLY_NAME,
    name = 'uploadFile',
    setFile,
    truncateFileNameStringLength = 25,
    useDetails = false,
}: FileSelectorProps) {
    const hiddenFileInput = React.useRef(null);
    const [internalFile, setInternalFile] = React.useState<File | null | undefined>(undefined);

    React.useEffect(() => {
        setInternalFile(file);
    }, [file]);

    function handleClick() {
        if (!hiddenFileInput || !hiddenFileInput.current) return;

        //@ts-ignore - object is possibly null error
        hiddenFileInput.current.click();
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            if (!isValidFileType(e.currentTarget.files[0].name, generateAllowedFileTypeArray(allowedFileTypesForUpload))) {
                alert('Sorry, that file type is not allowed');
                e.currentTarget.value = '';
                return;
            }

            if (e.currentTarget.files[0].size > maxUploadFileSize) {
                alert(`Max file size allowed: ${maxUploadFileSizeFriendlyName}`);
                e.currentTarget.value = '';
                return;
            }

            setInternalFile(e.currentTarget.files[0]);
            setFile(e.currentTarget.files[0]);
        }
    }

    return (
        <>
            <StyledContainer>
                <StyledContainer>
                    <Button onClick={handleClick}>{buttonText}</Button>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        accept={allowedFileTypesForUpload}
                        name={name}
                        id={name}
                        style={{ display: 'none' }}
                        onChange={handleFile}
                    />
                    <MedSolSpace w={4} />
                    {useDetails && (
                        <StyledDetailsContainer>
                            <Typography variant="p2_strong">{truncateFileNameString(internalFile?.name || '', truncateFileNameStringLength)}</Typography>
                            <Typography variant="p2" color="Gray_500">
                                {`Accepted file types: (${maxUploadFileSizeFriendlyName} max)`}
                            </Typography>
                            <Typography variant="p2" color="Gray_500">
                                {generateAllowedFileTypeDisplay(allowedFileTypesForUpload)}
                            </Typography>
                        </StyledDetailsContainer>
                    )}
                </StyledContainer>
            </StyledContainer>
            <MedSolValidationMessage status={'error'} text={errorText || ''} />
        </>
    );
}
