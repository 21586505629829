import styled from 'styled-components';
import { MedSolIcon, MedSolIconOptions, TIconOptions } from '@ms/medsol-components';
import { Status } from '../../models/app-types';
import { generateTaskStatusIconColor } from '../../utilities/app-utilities';

const StyledTaskCardIcon = styled(MedSolIcon)<{ status?: Status | undefined }>`
    grid-area: icon;
    display: flex;
    padding: ${(props) => props.theme.spacing(4)};
    align-items: center;
    justify-content: center;
    border-right: ${(props) => `1px solid ${props.theme.colors.Concrete}`};
    height: 100%;
    width: 100%;
    fill: ${(props) => generateTaskStatusIconColor(props.status)};
`;

interface ITaskCardStatusIconProps {
    iconName?: TIconOptions;
    status?: Status | undefined;
}

export function TaskCardStatusIcon({ iconName = MedSolIconOptions.Warning, status }: ITaskCardStatusIconProps) {
    return <StyledTaskCardIcon name={iconName} status={status} />;
}
