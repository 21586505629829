import React from 'react';
import { Button, ButtonProps } from '../Button';
import styled from 'styled-components';

const StyledLinkButton = styled(Button)<{ disabled?: boolean }>`
    &&& {
        background-color: inherit;
        padding: ${(props) => props.theme.spacing(2)};
    }
    &&& {
        &:hover {
            text-decoration: underline;
        }
    }
`;

interface LinkButtonProps extends ButtonProps {}

export function LinkButton({ children, ...rest }: LinkButtonProps) {
    return <StyledLinkButton {...rest}>{children}</StyledLinkButton>;
}
