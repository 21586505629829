import React from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from '../constants/AppInsights';
import { useMicrosoftGraphUserInfo } from '../queries/useMicrosoftGraphUserInfo';

export function AppInsightsProvider({ children }: { children: React.ReactNode }) {
    const { data } = useMicrosoftGraphUserInfo();

    // set user id as logged in user email
    if (data && data.mail) {
        appInsights.context.user.id = data.mail;
    }

    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
}
