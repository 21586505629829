import React from 'react';
import { MedSolIcon, MedSolTheme, Typography } from '@ms/medsol-components';
import { Hr } from '../Hr';
import { MiscellaneousDocumentForm } from '../MiscellaneousDocumentForm';
import { PadiDrawerContent, PadiDrawerHeader } from '../PadiDrawer';
import styled from 'styled-components';
import { LicenseForm } from '../../features/credentials/licenses/components/LicenseForm';
import { CertificationForm } from '../../features/credentials/certifications/components/CertificationForm';
import { DrawerBackButton } from '../PadiDrawer/_/DrawerBackButton';
import { CertificationLogic } from '../../features/credentials/certifications/logic';
import { LicenseLogic } from '../../features/credentials/licenses/logic';

// Add Document Forms
const AddDocumentFormModel = {
    CERT: 'cert',
    COMPACT_LICENSE: 'compactLicense',
    MISC: 'misc',
    STATE_LICENSE: 'stateLicense',
} as const;

type FormsType = 'cert' | 'compactLicense' | 'misc' | 'stateLicense' | '';

const ADD_DOCUMENT_TYPE_OPTIONS = [
    { label: 'Certification', value: AddDocumentFormModel.CERT },
    { label: 'Compact License', value: AddDocumentFormModel.COMPACT_LICENSE },
    { label: 'State License', value: AddDocumentFormModel.STATE_LICENSE },
    { label: 'Other (Miscellaneous)', value: AddDocumentFormModel.MISC },
];

const StyledDocumentTypeList = styled.ul`
    list-style: none;
`;

const StyledDocumentTypeListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    cursor: pointer;

    :first-child {
        padding-top: 0;
    }

    &:hover {
        span {
            font-weight: 800;
        }
    }
`;

type DrawerAddDocumentState = 'idle' | 'adding' | 'error' | 'success';

function generateHeaderTitle(drawerState: DrawerAddDocumentState, selectedForm: FormsType): string {
    if (drawerState === 'idle') return 'Add Document';
    if (drawerState === 'adding' && selectedForm === 'misc') return 'Add Other Document';
    if (drawerState === 'adding' && selectedForm === 'stateLicense') return 'Add State License';
    if (drawerState === 'adding' && selectedForm === 'compactLicense') return 'Add Compact License';
    if (drawerState === 'adding' && selectedForm === 'cert') return 'Add Certification';

    return '';
}

function generateHeaderSubtitle(drawerState: DrawerAddDocumentState, selectedForm: FormsType): string {
    if (drawerState === 'idle') return 'Which type of document would you like to add?';
    if (drawerState === 'adding') return 'Uploaded documents will be visible to the traveler';

    return '';
}

export function DrawerAddDocument() {
    const [drawerState, setDrawerState] = React.useState<DrawerAddDocumentState>('idle');
    const [selectedForm, setSelectedForm] = React.useState<FormsType>('');

    const headerTitle = generateHeaderTitle(drawerState, selectedForm);
    const headerSubtitle = generateHeaderSubtitle(drawerState, selectedForm);

    return (
        <>
            <PadiDrawerHeader title={headerTitle} subtitle={headerSubtitle}>
                {drawerState !== 'idle' && <DrawerBackButton onClick={() => setDrawerState('idle')} />}
            </PadiDrawerHeader>

            {drawerState === 'idle' ? (
                <PadiDrawerContent>
                    <StyledDocumentTypeList>
                        {ADD_DOCUMENT_TYPE_OPTIONS.map((option) => (
                            <>
                                <StyledDocumentTypeListItem
                                    key={option.label}
                                    onClick={() => {
                                        setSelectedForm(option.value);
                                        setDrawerState('adding');
                                    }}
                                >
                                    <Typography variant="p1" tag="span">
                                        {option.label}
                                    </Typography>
                                    <MedSolIcon name="ChevronRight" color={MedSolTheme.colorsV2.Gray_600} />
                                </StyledDocumentTypeListItem>
                                <Hr size="sm" />
                            </>
                        ))}
                    </StyledDocumentTypeList>
                </PadiDrawerContent>
            ) : (
                <>
                    <MiscellaneousDocumentForm isActive={selectedForm === 'misc'} />
                    <CertificationForm isActive={selectedForm === 'cert'} certification={CertificationLogic.getEmptyCertification()} />
                    <LicenseForm isActive={selectedForm === 'stateLicense'} license={LicenseLogic.getEmptyLicense()} />
                    <LicenseForm isActive={selectedForm === 'compactLicense'} license={LicenseLogic.getEmptyCompactLicense()} isCompact={true} />
                </>
            )}
        </>
    );
}
