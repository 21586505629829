import styled from "styled-components";

export const PageHeading = styled.h4`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #263349;
`;
