import React from 'react';
import { MedSolButton, MedSolSpace, Typography } from '@ms/medsol-components';
import { PadiDrawerContent, PadiDrawerFooter, PadiDrawerHeader } from '../../../../components/PadiDrawer';
import { Image, StyledImageContainer, StyledImagesContainer } from '../../../../components/Image';
import { DrawerBackButton } from '../../../../components/PadiDrawer/_/DrawerBackButton';
import { LicenseForm } from './LicenseForm';
import { ExpirationStatusEnum } from '@ms/credentials/dist/models/expiration-status';
import { useGetLicenseQuery } from '../hooks';
import { LicenseLogic } from '../logic';
import { Button } from '../../../../components/Button';
import { useModal } from '../../../../hooks/useModal';
import { useDeleteDocumentQuery } from '../../../../queries/useDeleteDocumentQuery';
import { useTravelerId } from '../../../../hooks/useTravelerId';
import { useMicrosoftGraphUserGroups } from '../../../../queries/useMicrosoftGraphUserGroups';
import { StyledButtonFooter } from '../../../../components/DrawerDocumentDetails/styles';

type LicenseDetailsDrawerState = 'viewing' | 'editing';

export function LicenseDetailsDrawer({ licenseId }: { licenseId: string }) {
    const [drawerState, setDrawerState] = React.useState<LicenseDetailsDrawerState>('viewing');
    const { data: license, status: licenseStatus } = useGetLicenseQuery(licenseId);
    const { openDeleteDocumentModal } = useModal();
    const { travelerId } = useTravelerId();
    const { hasDeletePermission } = useMicrosoftGraphUserGroups();
    const deleteDocumentMutation = useDeleteDocumentQuery();
    const documentId = license?.frontSideBlobId || (license?.backSideBlobId as string);

    // automatically open the edit drawer when the certification is expired and there is no expiration date (meaning its incomplete from PULSE probably)
    React.useEffect(() => {
        if (license?.status === ExpirationStatusEnum.Expired && license.expirationDate === null) {
            setDrawerState('editing');
        }
    }, [license]);

    return (
        <>
            <PadiDrawerHeader
                title={LicenseLogic.getFormattedTitle(license?.stateLicence, license?.isCompact)}
                subtitle={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="p1" color="Gray_500">
                            {LicenseLogic.getStatusDescription(
                                license?.isComplete,
                                license?.expirationDate || 0,
                                license?.status || ExpirationStatusEnum.Expired
                            )}
                        </Typography>
                        <Typography variant="p1" color="Gray_500">
                            {LicenseLogic.getFormattedLicenseNumber(license?.licenceNumber)}
                        </Typography>
                    </div>
                }
            >
                {drawerState !== 'viewing' && <DrawerBackButton onClick={() => setDrawerState('viewing')} />}
            </PadiDrawerHeader>

            {drawerState === 'editing' && license !== undefined && (
                <LicenseForm license={LicenseLogic.convertLicenseModelToLicenseForm(license)} isActive={true} />
            )}

            {drawerState === 'viewing' && (
                <>
                    <PadiDrawerContent>
                        {licenseStatus === 'loading' && (
                            <Typography variant="p1" color="Gray_500">
                                Loading license details...
                            </Typography>
                        )}
                        {licenseStatus === 'error' && (
                            <Typography variant="p1" color="Gray_500">
                                Error loading license details...
                            </Typography>
                        )}
                        {licenseStatus === 'success' && (
                            <StyledImagesContainer>
                                {license?.frontSideUri && (
                                    <StyledImageContainer>
                                        <Typography variant="p1" color="Gray_500">
                                            Front
                                        </Typography>
                                        <Image width="350px" src={license?.frontSideUri} alt="License Front Side" />
                                    </StyledImageContainer>
                                )}

                                {license?.backSideUri && (
                                    <>
                                        <MedSolSpace h={6} />
                                        <StyledImageContainer>
                                            <Typography variant="p1" color="Gray_500">
                                                Back
                                            </Typography>
                                            <Image width="350px" src={license?.backSideUri} alt="License Back Side" />
                                        </StyledImageContainer>
                                    </>
                                )}
                            </StyledImagesContainer>
                        )}
                    </PadiDrawerContent>
                    <PadiDrawerFooter>
                        <StyledButtonFooter>
                            {/* only show delete button if doucmentId is present. */}
                            {!!documentId && process.env.REACT_APP_SHOW_DELETE_MODAL === 'true' && hasDeletePermission ? (
                                <Button
                                    variant="danger"
                                    onClick={() => {
                                        openDeleteDocumentModal({ documentId, travelerId, deleteDocumentMutation });
                                    }}
                                >
                                    Delete
                                </Button>
                            ) : null}
                            <MedSolButton variant="secondary" onClick={() => setDrawerState('editing')}>
                                Edit
                            </MedSolButton>
                        </StyledButtonFooter>
                    </PadiDrawerFooter>
                </>
            )}
        </>
    );
}
