import React from 'react';
import { useModal } from '../../hooks/useModal';
import { Button } from '../Button';
import { ModalV2Body, ModalV2Header, ModalV2Footer, Typography } from '@ms/medsol-components';
import { Spinner } from '../Spinner';
import { useTravelerId } from '../../hooks/useTravelerId';
import { usePadiDocumentsQuery } from '../../queries/usePadiDocumentsQuery';
import { ButtonPrimary } from '../ButtonPrimary';
import { ITaskCardPropsExtended } from '../../models/app-types';

export interface IModalResetDocumentsProps {
    selectedTask?: ITaskCardPropsExtended;
}

export function ModalResetDocuments({ selectedTask }: IModalResetDocumentsProps) {
    const { closeModal } = useModal();
    const { resetDocumentMutation } = usePadiDocumentsQuery();
    const { travelerId } = useTravelerId();

    return (
        <>
            {resetDocumentMutation.isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner className="spinner" />
                </div>
            ) : (
                <>
                    <ModalV2Header hasDivider={false} onClose={closeModal}>
                        <Typography variant="head4_strong">Reset Document</Typography>
                    </ModalV2Header>
                    <ModalV2Body>
                        <Typography align="center" variant="head4">
                            Are you sure you want to reset the selected document? This cannot be undone.{' '}
                        </Typography>
                    </ModalV2Body>
                    <ModalV2Footer hasDivider={false}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                No, Cancel
                            </Button>
                            <ButtonPrimary
                                onClick={() => {
                                    if (selectedTask) {
                                        resetDocumentMutation.mutate({
                                            travelerId,
                                            requirementId: selectedTask.resetId,
                                        });
                                    }
                                }}
                            >
                                Yes, Reset
                            </ButtonPrimary>
                        </div>
                    </ModalV2Footer>
                </>
            )}
        </>
    );
}
