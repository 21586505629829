import React from 'react';
import { Button, ButtonProps } from '../Button';

type ButtonPrimaryProps = Omit<ButtonProps, 'variant'>;

export function ButtonPrimary({ children, ...rest }: ButtonPrimaryProps) {
    return (
        <Button variant="primary" {...rest}>
            {children}
        </Button>
    );
}
