import { ISelectOption } from '@ms/medsol-components';
import React from 'react';
import { useQuery } from 'react-query';
import { useApi } from '../contexts/api-context';
import { CertificationOptionDto, StateDto } from '../models/app-types';

const ProfileMetaDataLogic = {
    getStateOptions(states?: StateDto[]): ISelectOption[] {
        if (!states) return [];
        return states.map((item: StateDto) => ({ label: item.name, value: item.value }));
    },
    getCompactStateOptions(states?: StateDto[], compactStates?: string[]): ISelectOption[] {
        if (!states || !compactStates) return [];

        // compactStates are formatted as the abbreviation for the states, but we want the label to be the full state name
        // if states exist, find the matching state and return correct label and value
        return compactStates.map((datum) => {
            return { label: states.find((state) => state.value === datum)?.name || datum, value: datum };
        });
    },
    getCertificationOptions(certifications?: CertificationOptionDto[]): ISelectOption[] {
        if (!certifications) return [];
        return certifications.map((item: CertificationOptionDto) => ({ label: item.name, value: item.value }));
    },
};

export function useProfileMetadata() {
    const { PadiAPI } = useApi();

    const { data, status } = useQuery('profileMetadata', () => PadiAPI?.getProfileMetaData(), {
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    return React.useMemo(
        () => ({
            certificationOptions: ProfileMetaDataLogic.getCertificationOptions(data?.certifications),
            compactStateOptions: ProfileMetaDataLogic.getCompactStateOptions(data?.states, data?.compactStates),
            stateOptions: ProfileMetaDataLogic.getStateOptions(data?.states),
            status,
        }),
        [data, status]
    );
}
