import React from 'react';
import { getDrawerContent, useDrawer } from '../../hooks/useDrawer';
import { Drawer } from '../Drawer';
import styled from 'styled-components';
import {
    StyledDrawerContainer,
    StyledDrawerContentNew,
    StyledDrawerFooterNew,
    StyledDrawerHeaderNew,
    StyledDrawerHeaderTitle,
    StyledDrawerLightBar,
} from './styles';
import { useEventListener } from '../../hooks/useEventListener';
import { MedSolIcon, MedSolSpace, Typography } from '@ms/medsol-components';
import { Hr } from '../Hr';

const StyledButton = styled.div`
    position: absolute;
    right: 4px;
    top: 8px;
    padding: 4px;
    cursor: pointer;
    z-index: 2;
`;

function PadiDrawerCloseButton() {
    const { closeDrawer } = useDrawer();

    return (
        <StyledButton
            onClick={() => {
                closeDrawer();
            }}
        >
            <MedSolIcon name="Cross" color={'#4B4D52'} />
        </StyledButton>
    );
}

export function PadiDrawer() {
    const { isOpen, closeDrawer, drawerKey, drawerProps } = useDrawer();

    const escHandler = React.useCallback(
        (event: KeyboardEvent) => {
            if (event.key === '27') {
                if (isOpen) {
                    closeDrawer();
                }
            }
        },
        [isOpen, closeDrawer]
    );

    useEventListener('keydown', escHandler);

    //@ts-ignore - complicated typing issue here for dynamic typescript content generation with dynamic props
    const Content = React.createElement(getDrawerContent(drawerKey), drawerProps);

    return (
        <Drawer isOpen={isOpen}>
            <StyledDrawerContainer>
                <StyledDrawerLightBar />
                <PadiDrawerCloseButton />
                {Content}
            </StyledDrawerContainer>
        </Drawer>
    );
}

export function PadiDrawerHeader({
    title = '',
    subtitle = '',
    children,
}: {
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    children?: React.ReactNode;
}) {
    return (
        <StyledDrawerHeaderNew>
            {title && (
                <StyledDrawerHeaderTitle variant={'head3'} color="Gray_700" weight={500}>
                    {title}
                </StyledDrawerHeaderTitle>
            )}
            {subtitle && (
                <>
                    <MedSolSpace h={2} />
                    {typeof subtitle === 'string' ? (
                        <Typography variant="p1" color="Gray_500">
                            {subtitle}
                        </Typography>
                    ) : (
                        subtitle
                    )}
                </>
            )}
            {children}
            <Hr size="sm" />
        </StyledDrawerHeaderNew>
    );
}

export function PadiDrawerContent({ children }: { children: React.ReactNode }) {
    return <StyledDrawerContentNew>{children}</StyledDrawerContentNew>;
}

export function PadiDrawerFooter({ children }: { children: React.ReactNode }) {
    return <StyledDrawerFooterNew>{children}</StyledDrawerFooterNew>;
}
