import { useTravelerId } from './useTravelerId';
import { PadiDocumentDTOExtended } from '../models/app-types';
import { useDeleteDocumentQuery } from '../queries/useDeleteDocumentQuery';

export function useDeleteDocument(selectedDocument?: PadiDocumentDTOExtended) {
    const { travelerId } = useTravelerId();
    const deleteDocumentMutation = useDeleteDocumentQuery();

    return {
        documentId: selectedDocument?.storageId || '',
        travelerId,
        deleteDocumentMutation,
    };
}
