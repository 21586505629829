import React from 'react';
import { MedSolSpace } from '@ms/medsol-components';
import styled from 'styled-components';
import { TaskCardDescription } from './task-card-description';

const StyledTaskCardDetails = styled.div`
    grid-area: details;
    padding: ${(props) => props.theme.spacing(4)};
    display: flex;
    flex-flow: column;
    justify-content: center;
    overflow: hidden;
`;

export const StyledStatusDesc = styled.span`
    color: ${(props) => props.theme.colors.Silver};
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;

    @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 14px;
    }
`;

const StyledTaskTitle = styled.span`
    font-weight: bold;
    line-height: 20px;
    color: ${(props) => props.theme.colors.SincereSlate};

    font-size: 14px;
    @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 16px;
    }
`;

const StyledTaskExpiredDate = styled.span`
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.Error};
`;

export const TaskCardDescriptionWrapper = styled.div`
    display: flex;
    flex-flow: column;

    @media (${(props) => props.theme.breakpoints.sm}) {
        align-items: center;
        flex-flow: row;
    }
`;

interface ITaskCardDescriptionContainerProps {
    title: string;
    statusDescription?: string;
    expiredDate?: string;
    isGlobal?: boolean;
    documentDescription?: React.ReactNode;
}

export function TaskCardDescriptionContainer(props: ITaskCardDescriptionContainerProps) {
    const { title = '', statusDescription = '', expiredDate = '', documentDescription } = props;
    return (
        <StyledTaskCardDetails>
            <StyledStatusDesc>{statusDescription}</StyledStatusDesc>
            <TaskCardDescriptionWrapper>
                <StyledTaskTitle>{title}</StyledTaskTitle>
                <MedSolSpace w={2} />
                <StyledTaskExpiredDate>{expiredDate}</StyledTaskExpiredDate>
            </TaskCardDescriptionWrapper>
            <TaskCardDescription>{documentDescription}</TaskCardDescription>
        </StyledTaskCardDetails>
    );
}
