import { AccountInfo } from '@azure/msal-common';
import Axios from 'axios';
import { GRAPH_BASE_URL, PADI_BASE_URL } from '../constants/api-constants';
import BaseApi from './BaseApi';
import { GraphService, IGraphService } from './GraphService';
import { IPadiService, PadiService } from './PadiService';

export interface IApiServices {
    GraphAPI: IGraphService | null;
    PadiAPI: IPadiService | null;
}

export default function createApi(token: string | null, account: AccountInfo | null): IApiServices {
    if (!token) {
        return {
            PadiAPI: null,
            GraphAPI: null,
        };
    }

    // create base axios instance
    const padiAxiosInstance = Axios.create();
    const graphAxiosInstance = Axios.create();

    const PadiApi = BaseApi(padiAxiosInstance, PADI_BASE_URL, '', token);
    const GraphApi = BaseApi(graphAxiosInstance, GRAPH_BASE_URL, '', token);

    return {
        PadiAPI: PadiService(PadiApi),
        GraphAPI: GraphService(GraphApi, account),
    };
}

export interface IBaseAPI {
    get: <T>(url: string) => Promise<T>;
    otherGet: <T>(url: string) => Promise<T>;
    post: <T>(url: string, payload: {}) => Promise<T>;
    upload: <T>(url: string, formData: FormData) => Promise<T>;
    postForFiles: <T>(url: string, formData: FormData) => Promise<T>;
    remove: <T>(url: string) => Promise<T>;
}
