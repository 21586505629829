import React from 'react';
import { Button, ButtonProps } from '../Button';
import styled from 'styled-components';

const StyledWarningButton = styled(Button)<{ disabled?: boolean }>`
    &&& {
        color: ${(props) => (props.disabled ? props.theme.colors.Aluminum : props.theme.colors.Error)};
    }
`;

interface IWarningButtonProps extends ButtonProps {}

export function WarningButton({ children, ...rest }: IWarningButtonProps) {
    return <StyledWarningButton {...rest}>{children}</StyledWarningButton>;
}
