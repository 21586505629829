import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: '1dcb692b-21b6-4d57-aadd-575f0afa990b',
        authority: 'https://login.microsoftonline.com/25141f81-e52d-4bbc-b4c6-5ad17717b3e8',
        knownAuthorities: [],
        cloudDiscoveryMetadata: '',
        navigateToLoginRequestUrl: true,
        clientCapabilities: ['CP1'],
        redirectUri: window.location.origin, //'http://localhost:3000/',
        postLogoutRedirectUri: window.location.origin, //'http://localhost:3000/',
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['openid'],
};
