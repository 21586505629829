import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from '../../../contexts/api-context';
import { useTravelerId } from '../../../hooks/useTravelerId';
import { generateCertificationURL, generatePadiDocumentsAPIWithTravelerId } from '../../../utilities/app-utilities';
import { CertificationLogic } from './logic';

export function useUploadCertificationMutation() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();
    const queryClient = useQueryClient();

    //@ts-ignore
    return useMutation(PadiAPI?.uploadCertification, {
        enabled: !!PadiAPI && !!travelerId,
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
            queryClient.invalidateQueries(generateCertificationURL(travelerId));
        },
    });
}

export function useGetCertificationQuery(certificationId: string) {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();

    return useQuery(['certification', certificationId], () => PadiAPI?.getCertification(travelerId, certificationId), {
        enabled: PadiAPI !== null && travelerId !== '' && certificationId !== '',
        staleTime: 5 * 60 * 1000, // 5 minutes
    });
}

export function useGetCertificationsQuery() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();

    const { data, ...rest } = useQuery(generateCertificationURL(travelerId), () => PadiAPI?.getCertifications(travelerId), {
        enabled: PadiAPI !== null && travelerId !== '',
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    return React.useMemo(() => {
        const certificationTasks = data?.map((cert) => CertificationLogic.generateCertificationTaskCardProps(cert)) || [];

        return {
            data,
            certificationTasks,
            ...rest,
        };
    }, [data, rest]);
}
