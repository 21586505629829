import React from 'react';
import { Form, Formik } from 'formik';
import { FileSelector } from '../FileSelector';
import { RequirementField } from '../RequirementField';
import { Hr } from '../Hr';
import { DocumentUploadDTO } from '../../models/app-types';
import * as Yup from 'yup';
import { ButtonPrimary } from '../ButtonPrimary';
import { MedSolValidationMessage } from '@ms/medsol-components';
import { PadiDrawerContent, PadiDrawerFooter } from '../PadiDrawer';
import { useMutation, useQueryClient } from 'react-query';
import { generatePadiDocumentsAPIWithTravelerId } from '../../utilities/app-utilities';
import { useApi } from '../../contexts/api-context';
import { useTravelerId } from '../../hooks/useTravelerId';
import { useDrawer } from '../../hooks/useDrawer';

type MiscellaneousDocumentFormType = DocumentUploadDTO;

const EMPTY_MISCELLANEOUS_DOCUMENT: MiscellaneousDocumentFormType = {
    description: '',
    fields: '',
    file: undefined,
};

const getMiscellaneousDocumentValidationSchema = () =>
    Yup.object({
        description: Yup.string().required('Description is required'),
        file: Yup.mixed().required('File is required'),
        fields: Yup.mixed(),
    });

function useMiscDocumentUploadMutation() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();
    const queryClient = useQueryClient();

    // @ts-ignore - need to update how the api service itself is built to fix this.
    return useMutation(PadiAPI.uploadSpecialDocumentRequirement, {
        enabled: !!PadiAPI && travelerId,
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
        },
    });
}

export function MiscellaneousDocumentForm({ isActive = true }: { isActive?: boolean }) {
    const { travelerId } = useTravelerId();
    const { mutate, status: mutationStatus, reset: resetMutation } = useMiscDocumentUploadMutation();
    const { closeDrawer } = useDrawer();

    // to reset the formik fields and also reset mutation
    function handleResetForm(resetForm: () => void) {
        resetForm(); // will reset the form
        resetMutation(); // will reset the mutation
    }

    if (!isActive) return null;

    return (
        <Formik
            initialValues={EMPTY_MISCELLANEOUS_DOCUMENT}
            validationSchema={getMiscellaneousDocumentValidationSchema}
            onSubmit={(values) => {
                console.log('submitting values for', values);
                mutate(
                    { travelerId, payload: values },
                    {
                        onSuccess: () => {
                            closeDrawer();
                        },
                    }
                );
            }}
        >
            {({ values, errors, isSubmitting, setFieldValue, resetForm }) => (
                <>
                    <PadiDrawerContent>
                        <Form id="add-document-form">
                            <RequirementField type="Text" name="description" label="Description" isRequired error={errors['description']} />
                            <Hr />
                            <FileSelector file={values['file']} setFile={(file) => setFieldValue('file', file)} useDetails />
                            {errors['file'] && <MedSolValidationMessage status={'error'} text={errors['file']} />}
                            <Hr />
                        </Form>
                    </PadiDrawerContent>
                    <PadiDrawerFooter>
                        {mutationStatus !== 'error' && (
                            <ButtonPrimary type="submit" form="add-document-form" disabled={false} isLoading={isSubmitting || mutationStatus === 'loading'}>
                                Save
                            </ButtonPrimary>
                        )}
                        {mutationStatus === 'error' && (
                            <>
                                <MedSolValidationMessage status="error" text="Error uploading, please try again." />
                                <ButtonPrimary
                                    type="reset"
                                    form="add-document-form"
                                    disabled={false}
                                    onClick={() => {
                                        handleResetForm(resetForm);
                                    }}
                                >
                                    Try Again
                                </ButtonPrimary>
                            </>
                        )}
                    </PadiDrawerFooter>
                </>
            )}
        </Formik>
    );
}
