import React from 'react';
import { Formik, Form } from 'formik';
import { FileSelector } from '../../../../components/FileSelector';
import { useTravelerId } from '../../../../hooks/useTravelerId';
import { Hr } from '../../../../components/Hr';
import { ButtonPrimary } from '../../../../components/ButtonPrimary';
import { PadiDrawerContent, PadiDrawerFooter } from '../../../../components/PadiDrawer';
import { useDrawer } from '../../../../hooks/useDrawer';
import { MedSolSpace } from '@ms/medsol-components';
import { RequirementField } from '../../../../components/RequirementField';
import { useProfileMetadata } from '../../../../hooks/useProfileMetadata';
import { LicenseFormType } from '../types';
import { generateLicenseURL } from '../../../../utilities/app-utilities';
import { useQueryClient } from 'react-query';
import { useUploadLicenseMutation } from '../hooks';
import { LicenseLogic } from '../logic';

interface LicenseFormProps {
    isCompact?: boolean;
    isActive: boolean;
    license: LicenseFormType;
}

export function LicenseForm({ license, isCompact = false, isActive = true }: LicenseFormProps) {
    const { travelerId } = useTravelerId();
    const { mutate } = useUploadLicenseMutation(); // do something with mutationStatus and resetMutation
    const { closeDrawer } = useDrawer();
    const { stateOptions, compactStateOptions } = useProfileMetadata();
    const queryClient = useQueryClient();

    if (!isActive) return null;

    return (
        <Formik
            initialValues={license}
            validationSchema={LicenseLogic.getLicenseFormValidationSchema}
            onSubmit={(values) => {
                console.log('submitting values for', values);
                mutate(
                    { travelerId, payload: values },
                    {
                        onSuccess: () => {
                            if (values.licenceId !== null) {
                                queryClient.invalidateQueries(generateLicenseURL(travelerId));
                                queryClient.invalidateQueries(['license', values.licenceId]);
                            }
                            closeDrawer();
                        },
                        onError: (error) => console.log('error', error),
                    }
                );
            }}
        >
            {({ errors, isSubmitting, setFieldValue }) => (
                <>
                    <PadiDrawerContent>
                        <Form id="add-document-form">
                            <RequirementField
                                type="Select"
                                options={isCompact ? compactStateOptions : stateOptions}
                                name="stateLicence"
                                label={isCompact ? 'Compact State' : 'State'}
                                isRequired
                                error={errors['stateLicence']}
                                disabled={license.stateLicence !== ''} // disabled if editing exising license
                            />
                            <MedSolSpace h={4} />
                            <RequirementField
                                name="licenceNumber"
                                label="License Number"
                                type="Text"
                                isRequired
                                error={errors['licenceNumber']}
                                disabled={license.licenceNumber !== ''} // disabled if editing exising license
                            />
                            <MedSolSpace h={4} />
                            <RequirementField name="expirationDate" label="Expiration Date" type="Date" isRequired error={errors['expirationDate']} />
                            <Hr />
                            <FileSelector
                                name="frontFile"
                                buttonText="Upload Front"
                                file={undefined}
                                setFile={(file) => setFieldValue('frontFile', file)}
                                useDetails
                                errorText={errors['frontFile']}
                            />
                            <Hr />
                            <FileSelector
                                name="backFile"
                                buttonText="Upload Back"
                                file={undefined}
                                setFile={(file) => setFieldValue('backFile', file)}
                                useDetails
                                errorText={errors['backFile']}
                            />
                            <Hr />
                        </Form>
                    </PadiDrawerContent>
                    <PadiDrawerFooter>
                        <ButtonPrimary type="submit" form="add-document-form" disabled={false} isLoading={isSubmitting}>
                            Save
                        </ButtonPrimary>
                    </PadiDrawerFooter>
                </>
            )}
        </Formik>
    );
}
