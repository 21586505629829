import React from 'react';

interface IUseSearch<T> {
    searchValue: string;
    setSearchValue: (searchString: string) => void;
    searchedData: T[];
}

export function useSearch<T>(initialData: T[], key: string): IUseSearch<T> {
    const [searchValue, setSearchValue] = React.useState('');

    const searchedData = initialData.filter((datum: any) => {
        const value = typeof datum[key] === 'string' ? datum[key].trim().toLowerCase() : '';

        if (value === '') {
            return false;
        }

        return value.toLowerCase().includes(searchValue.toLowerCase());
    });

    return React.useMemo(() => ({ searchedData, searchValue, setSearchValue }), [searchValue, searchedData]);
}
