import React from 'react';
import styled from 'styled-components';
import { generateMedSolStatusTextColor } from '../../utilities/medsol-input-utilities';
import { IMedSolInputProps } from '@ms/medsol-components';

const StyledSpan = styled.span<{ status: IMedSolInputProps['status'] }>`
    font-size: 12px;
    color: ${(props) => generateMedSolStatusTextColor(props.status)};
`;

export function MedSolStatusMessage({ text, status = 'default' }: { text?: string; status?: IMedSolInputProps['status'] }) {
    return <StyledSpan status={status}>{text}</StyledSpan>;
}
