import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 100px auto;
`;

export function AuthLoading() {
    return (
        <StyledContainer>
            <h6>Authentication in progress...</h6>
        </StyledContainer>
    );
}
