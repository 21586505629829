import React from 'react';
import styled from 'styled-components';

const StyledDrawerContainer = styled.div<{ isOpen: boolean }>`
    position: absolute;
    top: 58px;
    bottom: 0;
    right: ${(props) => (props.isOpen ? '0' : '-600px')};
    z-index: 2;
    height: calc(100% - 58px);
    width: auto;
    min-width: 400px;
    background-color: ${(props) => props.theme.colors.White};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
    border-left: ${(props) => `1px solid ${props.theme.colors.Concrete}`};
    transition: right 200ms ease-out;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`;

interface IDrawerProps {
    isOpen: boolean;
    children: React.ReactNode;
}

export function Drawer({ isOpen = false, children }: IDrawerProps) {
    return <StyledDrawerContainer isOpen={isOpen}>{children}</StyledDrawerContainer>;
}
