import React from 'react';
import { MedSolSpace } from '@ms/medsol-components';
import { PageHeading } from '../../components/PageHeading';
import { ViewTravelerDocuments } from '../../components/ViewTravelerDocuments';

export function Home() {
    return (
        <div>
            <PageHeading>PADI Home Page!</PageHeading>
            <MedSolSpace h={8} />
            <ViewTravelerDocuments />
        </div>
    );
}
