import { MedSolIconOptions, MedSolTheme, TIconOptions } from '@ms/medsol-components';
import React from 'react';
import styled from 'styled-components';
import { ITaskCardPropsExtended, PadiDocumentDTO, PadiDocumentType, RequirementType, Status } from '../../models/app-types';
import { TaskCardStatusIcon } from './task-card-status-icon';
import { TaskCardAction } from './task-card-action';
import { TaskCardSelection } from './task-card-selection';
import { TaskCardDescriptionContainer } from './task-card-description-container';

const generateBackgroundColorByStatus = (status: Status | undefined) => {
    switch (status) {
        case Status.Done:
            return MedSolTheme.colorsV2.Gray_100;
        case Status.Processing:
            return MedSolTheme.colorsV2.Gray_100;
        default:
            return MedSolTheme.colorsV2.White;
    }
};

const generateBoxShadowByStatus = (status: Status | undefined) => {
    switch (status) {
        case Status.Done:
            return 'none';
        case Status.Processing:
            return 'none';
        default:
            return 'rgba(0, 0, 0, 0.08) 0px 2px 4px';
    }
};

const StyledTaskCardContainer = styled.li`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px; //this is constrain task card to 1200px width
    margin: 0 auto;
`;

const StyledTaskCard = styled.div<{ status: Status | undefined; onClick?: (() => Window | null) | undefined; isLocked?: boolean }>`
    display: grid;
    grid-template-columns: 56px 1fr auto;
    grid-template-areas: 'icon details action';
    height: auto;
    max-height: 120px;
    border: ${(props) => `1px solid ${props.theme.colors.Concrete}`};
    border-radius: ${(props) => props.theme.spacing(2)};
    box-shadow: ${(props) => generateBoxShadowByStatus(props?.status)};
    background-color: ${(props) => generateBackgroundColorByStatus(props?.status)};
    overflow: hidden;
    cursor: ${(props) => {
        if (props.isLocked || !props.onClick) return '';

        return 'pointer';
    }};
    width: 100%;
    z-index: 2;
`;

export interface ITaskCardProps {
    id: string;
    status: Status | undefined;
    children?: React.ReactNode;
    iconName?: TIconOptions;
    title?: string;
    fileName?: string;
    statusDescription?: string;
    actionText?: string;
    className?: string;
    isSensitive?: boolean;
    isGlobal?: boolean;
    expiredDate?: string;
    startDate?: Date | string;
    dealNumber?: string;
    documentType?: PadiDocumentType;
    requirementType?: RequirementType;
    retentionType?: string;
    documentDetails?: PadiDocumentDTO;
    downloadUrl?: string;
    HistoryPanel?: React.ReactNode;
    documentDescription?: React.ReactNode;
}

export function TaskCard({
    status,
    iconName,
    title,
    statusDescription,
    actionText,
    isLocked,
    expiredDate,
    isGlobal,
    className,
    onClick,
    HistoryPanel,
    children,
    isSelectable,
    isSelected,
    handleSelectTask,
    documentDescription,
}: ITaskCardPropsExtended) {
    return (
        <StyledTaskCardContainer className={className}>
            <StyledTaskCard status={status} onClick={onClick} isLocked={isLocked}>
                <>
                    {isSelectable && <TaskCardSelection isSelected={isSelected} handleSelect={handleSelectTask} />}
                    {iconName && !isSelectable && <TaskCardStatusIcon iconName={iconName} status={status} />}
                    {title && (
                        <TaskCardDescriptionContainer
                            title={title}
                            statusDescription={statusDescription}
                            expiredDate={expiredDate}
                            isGlobal={isGlobal}
                            documentDescription={documentDescription}
                        />
                    )}
                    {isLocked ? <TaskCardAction actionText="" iconName={MedSolIconOptions.Lock} /> : actionText && <TaskCardAction actionText={actionText} />}
                </>
            </StyledTaskCard>
            {HistoryPanel && HistoryPanel}
        </StyledTaskCardContainer>
    );
}
