import { useTravelerId } from './useTravelerId';
import { PadiDocumentDTOExtended, RequirementFormField, Status } from '../models/app-types';
import { usePadiUploadRequirementMutation } from '../queries/usePadiUploadRequirementMutation';
import { usePadiSpecialDocumentUpload } from '../queries/usePadiSpecialDocumentUploadMutation';
import * as Yup from 'yup';

const FORM_FIELD_REQUIRED_MESSAGE = 'This field is required';

function generateFormFieldInitialValue(formField: RequirementFormField, documentStatus: Status) {
    // hide values if the document has been reset back to "New"
    if (documentStatus === Status.New) {
        return '';
    }

    if (formField.type === 'Text') {
        return formField.value || '';
    }

    if (formField.type === 'Date') {
        return formField.value ? formField.value.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2') : '';
    }
}

function generateValidationRule(formField: RequirementFormField) {
    if (formField.type === 'Text') {
        if (formField.isRequired) {
            return Yup.string().required(FORM_FIELD_REQUIRED_MESSAGE);
        } else {
            return Yup.string();
        }
    }

    if (formField.type === 'Date') {
        if (formField.isRequired) {
            return Yup.date().required(FORM_FIELD_REQUIRED_MESSAGE);
        } else {
            return Yup.date();
        }
    }

    if (formField.type === 'Number') {
        if (formField.isRequired) {
            return Yup.number().required(FORM_FIELD_REQUIRED_MESSAGE);
        } else {
            return Yup.number();
        }
    }
}

export interface IRequirementFormValues {
    file: File | null;
    [key: string]: any;
}

export function useUploadRequirement(selectedDocument?: PadiDocumentDTOExtended) {
    const { travelerId } = useTravelerId();
    const uploadRequirementMutation = usePadiUploadRequirementMutation();
    const uploadSpecialDocumentMutation = usePadiSpecialDocumentUpload(); //passing down special document mutation hook
    const formFields = selectedDocument?.formFields || [];

    const initialValues: IRequirementFormValues = formFields.reduce(
        // @ts-ignore - not sure why TS isnt finding the type
        (acc, formField) => ({ ...acc, [formField.name]: generateFormFieldInitialValue(formField, selectedDocument?.status) }),
        {
            file: null,
        }
    );

    return {
        formFields: selectedDocument?.formFields || [],
        initialValues,
        requirementTitle: selectedDocument?.taskProps?.title || 'No Title',
        requirementId: selectedDocument?.resetId,
        travelerId,
        uploadRequirementMutation,
        uploadSpecialDocumentMutation,
        validationSchema: Yup.object().shape(
            // @ts-ignore - not sure why TS isnt finding the type
            formFields.reduce((acc, formField) => ({ ...acc, [formField.name]: generateValidationRule(formField) }), {
                file: Yup.mixed().required('File Required'),
            })
        ),
    };
}
