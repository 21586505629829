import { useMutation, useQueryClient } from 'react-query';
import { generatePadiDocumentsAPIWithTravelerId } from '../utilities/app-utilities';
import { useApi } from '../contexts/api-context';
import { useTravelerId } from '../hooks/useTravelerId';
import { useDrawer } from '../hooks/useDrawer';

export function useDeleteDocumentQuery() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();
    const queryClient = useQueryClient();
    const { closeDrawer } = useDrawer();

    //@ts-ignore - complex typescript typing issue
    return useMutation(PadiAPI?.deleteDocument, {
        onSuccess: () => {
            closeDrawer();
            window.location.reload();
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
        },
    });
}
