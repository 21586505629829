import styled from 'styled-components';
import { useTravelerProfile } from '../../../queries/useTravelerProfile';
import { FeatureIndicator } from '../../../components/FeatureIndicator';
import { ContactTypeTag } from '../../../components/ContactTypeTag';
import { TravelerEmail } from '../../../components/TravelerEmail';
import { TravelerPhone } from '../../../components/TravelerPhone';
import { MedSolSpace, Typography } from '@ms/medsol-components';

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const FlexRow = styled.div`
    display: flex;
    flex-flow: row;
`;

export function TravelerProfile() {
    const { data: travelerProfile, travelerFullName } = useTravelerProfile();

    return (
        <>
            {travelerProfile && (
                <FlexColumn>
                    <FlexRow style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                        <Typography variant="head2_strong">{travelerFullName}</Typography>
                        <MedSolSpace w={6} />
                        <ContactTypeTag contactType={travelerProfile.contactType} />
                    </FlexRow>
                    <MedSolSpace h={2} />
                    <FlexRow style={{ flexWrap: 'wrap' }}>
                        <TravelerEmail email={travelerProfile.email} />
                        <MedSolSpace w={5} />
                        <TravelerPhone phone={travelerProfile.mobilePhone} />
                    </FlexRow>
                    <MedSolSpace h={2} />
                    <FlexRow style={{ flexWrap: 'wrap' }}>
                        <FeatureIndicator
                            isActive={travelerProfile.hasUltiProAccess}
                            displayText={travelerProfile.hasUltiProAccess ? 'UltiPro: Verified' : 'UltiPro: Unverified'}
                        />
                        <MedSolSpace w={5} />
                        <FeatureIndicator
                            isActive={travelerProfile.hasOktaAccess}
                            displayText={travelerProfile.hasOktaAccess ? 'Okta Email: Verified' : 'Okta Email: Unverified'}
                        />
                    </FlexRow>
                </FlexColumn>
            )}
        </>
    );
}
