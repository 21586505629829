import { IMedSolSpinnerProps, MedSolSpinner, MedSolTheme } from '@ms/medsol-components';

type TVariants = 'light' | 'dark';

function getVariantColor(variant: TVariants) {
    if (variant === 'light') {
        return MedSolTheme.colors.White;
    }

    if (variant === 'dark') {
        return MedSolTheme.colors.SincereSlate;
    }

    return MedSolTheme.colors.SincereSlate;
}

interface ISpinnerProps extends Partial<IMedSolSpinnerProps> {
    variant?: TVariants;
}

export function Spinner({ variant = 'dark', className, ...rest }: ISpinnerProps) {
    return <MedSolSpinner color={getVariantColor(variant)} size="md" thickness="8px" className={className} {...rest} />;
}
