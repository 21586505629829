import React from 'react';
import { useQuery, useMutation, useQueryClient, UseMutationResult, MutationStatus } from 'react-query';
import { ITaskCardPropsExtended, PadiDocumentDTO, PadiDocumentDTOExtended } from '../models/app-types';
import {
    generatePadiDocumentsAPIWithTravelerId,
    generateTaskPropsFromPadiDocuments,
    removeIncompletePermTaxFormWithExtension,
} from '../utilities/app-utilities';
import { useApi } from '../contexts/api-context';
import { useMicrosoftGraphUserGroups } from './useMicrosoftGraphUserGroups';
import { useModal } from '../hooks/useModal';
import { useTravelerId } from '../hooks/useTravelerId';
import { useDrawer } from '../hooks/useDrawer';

type PadiDocumentsAPIDTO = {
    travelerId: string;
    padiDocs: PadiDocumentDTO[];
};

type PadiDocumentsQuery = {
    data: PadiDocumentDTOExtended[];
    padiDocumentTasks: ITaskCardPropsExtended[];
    downloadRequirementsMutation: UseMutationResult<any, unknown, any, unknown>;
    resetDocumentMutation: UseMutationResult<any, unknown, any, unknown>;
    uploadFullApplicationMutation: UseMutationResult<any, unknown, any, unknown>;
    status: MutationStatus;
    isLoading: boolean;
    error: any;
    getSpecificPadiDocument: (documentId: string) => PadiDocumentDTOExtended | undefined;
};

export function usePadiDocumentsQuery(): PadiDocumentsQuery {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();
    const { hasAdminPermission, hasCMADPermission } = useMicrosoftGraphUserGroups();
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { closeDrawer } = useDrawer();

    const { data, status, isLoading, error } = useQuery<PadiDocumentsAPIDTO>(
        generatePadiDocumentsAPIWithTravelerId(travelerId), // query key
        async () => {
            return await PadiAPI?.getPadiDocuments(travelerId);
        },
        {
            enabled: !!PadiAPI && !!travelerId,
            staleTime: 300000,
        }
    );

    const formattedPayload: PadiDocumentDTOExtended[] = React.useMemo(() => {
        if (!data || !data.padiDocs) return [];

        // generate task card props
        return (
            data.padiDocs
                // extend object with task props
                .map((doc: PadiDocumentDTO) => {
                    return {
                        ...doc,
                        taskProps: generateTaskPropsFromPadiDocuments(doc, '', hasAdminPermission, hasCMADPermission),
                    };
                })
        );
    }, [data, hasAdminPermission, hasCMADPermission]);

    /* MUTATIONS */
    //@ts-ignore
    const resetDocumentMutation = useMutation(PadiAPI?.resetDocument, {
        enabled: !!PadiAPI && travelerId,
        // When mutate is called:
        onMutate: async (resetPayload) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(generatePadiDocumentsAPIWithTravelerId(travelerId));

            // Snapshot the previous value
            const previousDocuments = queryClient.getQueryData(generatePadiDocumentsAPIWithTravelerId(travelerId));

            // Optimistically update to the new value
            queryClient.setQueryData(generatePadiDocumentsAPIWithTravelerId(travelerId), (old: any) => {
                return {
                    ...old,
                    padiDocs: old.padiDocs.filter((doc: PadiDocumentDTOExtended) => doc.resetId !== resetPayload.requirementId),
                };
            });

            // Return a context object with the snapshotted value
            return { previousDocuments };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            //@ts-ignore - react query ts issue
            queryClient.setQueryData(generatePadiDocumentsAPIWithTravelerId(travelerId), context.previousTodos);
        },
        onSuccess: () => {
            closeDrawer();
            closeModal();
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
        },
    });

    //@ts-ignore
    const uploadFullApplicationMutation = useMutation(PadiAPI?.uploadFullApplication, {
        enabled: !!PadiAPI && travelerId,
        onSuccess: () => {
            closeDrawer();
            closeModal();
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
        },
    });

    //@ts-ignore - React-Query ts issue
    const downloadRequirementsMutation = useMutation(PadiAPI?.downloadRequirements, {
        enabled: !!PadiAPI,
    });

    const padiDocumentTasks =
        data?.padiDocs
            // extend object with task props
            .map((doc: PadiDocumentDTO) => generateTaskPropsFromPadiDocuments(doc, '', hasAdminPermission, hasCMADPermission)) || [];

    //only show either completed or incomplete with document history perm tax form with extension type deal
    const filteredPadiDocumentTasks = removeIncompletePermTaxFormWithExtension(padiDocumentTasks);

    function getSpecificPadiDocument(documentId: string) {
        const foundDoc = data?.padiDocs.find((doc: PadiDocumentDTO) => `${doc.resetId}-${doc.name}` === documentId) || undefined;

        if (!foundDoc) return undefined;

        return {
            ...foundDoc,
            taskProps: generateTaskPropsFromPadiDocuments(foundDoc, '', hasAdminPermission, hasCMADPermission),
        };
    }

    return {
        data: formattedPayload,
        padiDocumentTasks: filteredPadiDocumentTasks,
        downloadRequirementsMutation,
        resetDocumentMutation,
        uploadFullApplicationMutation,
        status,
        isLoading,
        error,
        getSpecificPadiDocument,
    };
}
