import React from 'react';
import { TaskCard } from '../TaskCard';
import { ITaskCardPropsExtended } from '../../models/app-types';
import styled from 'styled-components';

const StyledDocumentTaskCard = styled(TaskCard)<{ isSelected: boolean }>`
    box-shadow: ${(props) => (props.isSelected ? '0px 4px 24px rgba(0, 0, 0, 0.08)' : 'none')};
`;

interface IDocumentTaskCardProps extends ITaskCardPropsExtended {}

export function DocumentTaskCard(props: IDocumentTaskCardProps) {
    return <StyledDocumentTaskCard {...props} />;
}
