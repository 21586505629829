import { Typography } from '@ms/medsol-components';
import React from 'react';
import styled from 'styled-components';

const StyledTravelerEmail = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.Silver};
`;

export const TravelerEmail = React.memo(({ email = '' }: { email: string }) => (
    <StyledTravelerEmail href={`mailto:${email}`}>
        <Typography variant="p2">{email}</Typography>
    </StyledTravelerEmail>
));
