import React from 'react';
import styled from 'styled-components';

interface IMedSolLabel {
    for: string;
    children: string;
}

const StyledLabel = styled.label`
    margin: 0;
    padding: 0;
`;

export function MedSolLabel(props: IMedSolLabel) {
    return <StyledLabel htmlFor={props.for}>{props.children}</StyledLabel>;
}
