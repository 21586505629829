import { IMedSolInputProps, MedSolLabeledInput } from '@ms/medsol-components';
import { FieldInputProps } from 'formik';

interface ITextInputProps extends IMedSolInputProps {
    field?: FieldInputProps<any>;
}

export function TextInput({ field, ...rest }: ITextInputProps) {
    return <MedSolLabeledInput type="text" {...field} {...rest} />;
}
