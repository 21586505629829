import React from 'react';
import { MedSolSpace, Typography } from '@ms/medsol-components';
import { Button } from '../Button';
import { WarningButton } from '../WarningButton';
import { DocumentHistoryPanel } from '../DocumentHistoryPanel';
import { useModal } from '../../hooks/useModal';
import { PadiDrawerContent, PadiDrawerFooter, PadiDrawerHeader } from '../PadiDrawer';
import { usePadiDocumentsQuery } from '../../queries/usePadiDocumentsQuery';
import { useAuditLogAction } from '../../queries/useAuditLogAction';
import { CertificationDetailsDrawer } from '../../features/credentials/certifications/components/CertificationDetailsDrawer';
import { LicenseDetailsDrawer } from '../../features/credentials/licenses/components/LicenseDetailsDrawer';
import { AuditLogActions, ITaskCardPropsExtended } from '../../models/app-types';
import { SendAuditLogPayload } from '../../services/PadiService';
import { TaskCardDescription } from '../TaskCard/task-card-description';
import { useDeleteDocument } from '../../hooks/useDeleteDocument';
import { useMicrosoftGraphUserGroups } from '../../queries/useMicrosoftGraphUserGroups';
import { StyledButtonFooter } from './styles';
export interface IDocumentDetailsDrawerProps {
    selectedTask: ITaskCardPropsExtended;
}

// This filter can be refactored later, but for now it is fine for a quick fix.
function isDeleteAllowed(selectedTask: ITaskCardPropsExtended): boolean {
    const excludedDocTypeName = ['Full Application', 'Professional References', 'Get To Know Me'];
    if (!selectedTask) return false;
    if (selectedTask?.documentType === 'PandaDoc') return false;
    if (process.env.REACT_APP_SHOW_DELETE_MODAL === 'true') {
        return !excludedDocTypeName.includes(selectedTask?.title as string);
    } else {
        return false;
    }
}

export function DrawerDocumentDetails({ selectedTask }: IDocumentDetailsDrawerProps) {
    const { getSpecificPadiDocument } = usePadiDocumentsQuery();
    const { sendAuditLogAction } = useAuditLogAction();
    const { openModal, openResetDocumentModal, openUploadRequirementModal, openDeleteDocumentModal } = useModal();
    const { hasDeletePermission } = useMicrosoftGraphUserGroups();
    const selectedPadiDocument = selectedTask?.source === 'padi' ? getSpecificPadiDocument(selectedTask?.id || '') : undefined;
    const { documentId, travelerId, deleteDocumentMutation } = useDeleteDocument(selectedPadiDocument);

    // if task is not selected, return nothing
    if (!selectedTask) return <></>;

    // trying to fix bad code merge

    if (selectedTask.source === 'license') return <LicenseDetailsDrawer licenseId={selectedTask.id} />;
    if (selectedTask.source === 'certification') return <CertificationDetailsDrawer certificationId={selectedTask.id} />;

    return (
        <>
            <PadiDrawerHeader
                title={selectedTask?.title}
                subtitle={
                    <>
                        {selectedTask?.title?.split(' ').join('').toLocaleLowerCase() === 'permanenttaxresidentnotification' && (
                            <>
                                <TaskCardDescription>{selectedTask.documentDescription}</TaskCardDescription>
                                <MedSolSpace h={2} />
                            </>
                        )}
                        <Typography variant="p1" color="Gray_500">
                            {selectedTask?.statusDescription}
                        </Typography>
                    </>
                }
            />
            <PadiDrawerContent>
                <DocumentHistoryPanel document={selectedPadiDocument} />
            </PadiDrawerContent>
            <PadiDrawerFooter>
                {selectedTask?.isFullApplicationAdminModifiable && (
                    <Button
                        onClick={() => {
                            openModal('uploadFullApplication');
                        }}
                    >
                        Upload Full App
                    </Button>
                )}
                {selectedTask?.documentType === 'Upload' && (
                    <>
                        <Button
                            onClick={() => {
                                openUploadRequirementModal({ selectedDocument: selectedPadiDocument });
                            }}
                        >
                            Upload
                        </Button>
                        <MedSolSpace w={4} />
                    </>
                )}
                {selectedTask?.isResettable && (
                    <>
                        <WarningButton
                            onClick={() => {
                                openResetDocumentModal({ selectedTask });
                            }}
                        >
                            Reset
                        </WarningButton>
                        <MedSolSpace w={4} />
                    </>
                )}

                {selectedTask?.viewableUri && (
                    <StyledButtonFooter>
                        {isDeleteAllowed(selectedTask) && hasDeletePermission ? (
                            <Button
                                variant="danger"
                                onClick={() => {
                                    openDeleteDocumentModal({ documentId, travelerId, deleteDocumentMutation });
                                }}
                            >
                                Delete
                            </Button>
                        ) : null}
                        <Button
                            onClick={() => {
                                if (documentId) {
                                    const auditLogPayload: SendAuditLogPayload[] = [
                                        {
                                            action: AuditLogActions.Viewed,
                                            fileName: selectedPadiDocument?.fileName,
                                            documentName: selectedPadiDocument?.name,
                                            documentId,
                                            requirementId: selectedPadiDocument?.resetId,
                                        },
                                    ];
                                    sendAuditLogAction(auditLogPayload); // this call will let the BE know that user has viewed the document.
                                }
                                window.open(selectedTask?.viewableUri);
                            }}
                        >
                            View
                        </Button>
                    </StyledButtonFooter>
                )}
            </PadiDrawerFooter>
        </>
    );
}
