import { GraphEndpoints } from '../constants/api-constants';
import { IBaseAPI } from '.';
import { AccountInfo } from '@azure/msal-browser';

export interface IGraphService {
    getUserInfo: () => Promise<any>;
    getUserGroupsMemberOf: () => Promise<any>;
}

export function GraphService(api: IBaseAPI, account: AccountInfo | null): IGraphService {
    async function getUserInfo(): Promise<any> {
        return api.otherGet<any>(GraphEndpoints.Me);
    }

    async function getUserGroupsMemberOf(): Promise<any> {
        if (account) {
            return api.otherGet<any>(GraphEndpoints.MemberOf.replace('{:id}', account.localAccountId));
        }
    }

    return {
        getUserInfo,
        getUserGroupsMemberOf,
    };
}
