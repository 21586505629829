import * as Yup from 'yup';
import { CertificationFormType } from './types';
import { ITaskCardPropsExtended, Status } from '../../../models/app-types';
import { DATE_FORMAT_REGEX, formatDate, generateTaskStatusIcon } from '../../../utilities/app-utilities';
import { ICertificationModel } from '@ms/credentials/dist/models/certification.models';
import { ExpirationStatusEnum } from '@ms/credentials/dist/models/expiration-status';
import { CredsLogic } from '@ms/credentials/dist/logic/creds.logic';

export const CertificationLogic = {
    getEmptyCertification(): CertificationFormType {
        return {
            backFile: undefined,
            backSideBlobId: '',
            backSideUri: '',
            certificationId: '',
            certificationType: '',
            certificationUrl: '',
            daysLeftTillExpiration: 0,
            expirationDate: 0,
            frontFile: undefined,
            frontSideBlobId: '',
            frontSideUri: '',
            isActive: true,
            isComplete: false,
            isExpiringSoon: false,
            status: ExpirationStatusEnum.UpToDate,
        };
    },

    getCertificationFormValidationSchema() {
        return Yup.object({
            certificationType: Yup.string().required('This field is required'),
            expirationDate: Yup.string().required('This field is required').matches(DATE_FORMAT_REGEX['YYYY-MM-DD'], 'Must be a valid date'),
            frontFile: Yup.mixed().required('This field is required'),
            backFile: Yup.mixed().required('This field is required'),
        });
    },

    getCertificationFormData: (certification: CertificationFormType): FormData => {
        const formData = new FormData();

        Object.keys(certification).forEach((key) => {
            if (
                ![
                    'daysLeftTillExpiration',
                    'status',
                    'versionNumber',
                    'isComplete',
                    'backSideBlobId',
                    'backSideUri',
                    'frontSideBlobId',
                    'frontSideUri',
                ].includes(key) &&
                !key.startsWith('created') &&
                !key.startsWith('updated')
            ) {
                // @ts-ignore - not sure what the issue is here yet
                formData.append(key, certification[key]);
            }
        });

        return formData;
    },

    generateCertificationTaskCardProps(certification: ICertificationModel): ITaskCardPropsExtended {
        return {
            id: certification.certificationId || '',
            source: 'certification',
            title: this.getFormattedTitle(certification.certificationType),
            statusDescription: this.getStatusDescription(certification.isComplete || false, certification.expirationDate as Date, certification.status),
            isResettable: false,
            isSelected: false,
            isLocked: false,
            hasHistory: false,
            isSelectable: false,
            status: certification.isComplete ? Status.Done : Status.New,
            documentHistory: [],
            viewableUri: '',
            iconName: generateTaskStatusIcon(certification.isComplete ? Status.Done : Status.New),
            previawableUris: [certification.frontSideUri || '', certification.backSideUri || ''],
        };
    },
    getStatusDescription(
        isComplete: ICertificationModel['isComplete'],
        expirationDate: ICertificationModel['expirationDate'],
        status: ICertificationModel['status']
    ): string {
        return CredsLogic.getCredTileTextToDisplay(isComplete || false, expirationDate as Date, status || ExpirationStatusEnum.UpToDate);
    },
    getFormattedTitle(certificationType?: string): string {
        if (!certificationType) return '';
        return certificationType;
    },
    getFormattedCompletedDate(date?: Date): string {
        if (!date) return '';
        return `Completed: ${formatDate(date)}`;
    },
    getFormattedExpirationDate(expirationDate?: string | number | Date): string {
        if (!expirationDate) return '';
        return `Exp Date: ${formatDate(expirationDate)}`;
    },
    convertCertificationModelToCertificationForm(certification: ICertificationModel): CertificationFormType {
        return {
            ...certification,
            expirationDate: 0,
            frontFile: undefined, // convert to File object
            backFile: undefined, // convert to File object
        };
    },
};
