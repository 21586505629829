import styled from 'styled-components';
import { MedSolIcon } from '@ms/medsol-components';


// Styling for search and order by filters


export const StyledFiltersSelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 720px) {
       margin: ${(props) => props.theme.spacing(4)} 0;
    }
`;

export const StyledSortBy = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    padding: 0;
    align-items: flex-end;
`;

export const StyledSortByLabel = styled.span`
    font-size: 12px;
    line-height: 5px;
    letter-spacing: -0.1px;
    color: #868c96;
    margin: 8px 0px;
`;

export const StyledSortByTitle = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
`;
export const StyledFiltersDropdownWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 54px;
    height: 54px;
    top: 0px;
    background: #f4f5f6;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    &:hover {
        background: #f0f2f2;
    }
`;

export const StyledMedSolIcon = styled(MedSolIcon)<{color: string, margin?: string, size?: string}>`
    fill: ${(props) => props.color === 'black' ? props.theme.colors.Onyx : props.theme.colors.White};
    margin: ${(props) => (props.margin ? props.margin : 0 + 'px')};
    font-size: 20px;
`;

export const StyledFiltersDropDown = styled.div<{ show: boolean }>`
    position: absolute;
    border: 1px solid white;
    height: auto;
    width: 180px;
    background-color: ${(props) => props.theme.colors.White};
    border-radius: 16px;
    padding: 16px;
    box-shadow: rgb(0 0 0 / 16%) 0px 4px 24px;
    top: 58px;
    right: 16px;
    z-index: 5;
    display: ${(props) => (props.show ? 'flex' : 'none')};
    flex-flow: column;
    align-items: flex-start;
`;

export const StyledFiltersButton = styled.button`
    color: ${(props) => props.theme.colors.Black};
    background-color: transparent;
    text-decoration: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0px;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    &:hover {
       font-weight: 700;
    }
`;
