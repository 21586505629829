import create, { State } from 'zustand';

interface AdminModeState extends State {
    isAdminMode: boolean;
    setIsAdminMode: (val: boolean) => void;
}

// selectors
export const useAdminModeSelectors = {
    isAdminMode: (state: AdminModeState) => state.isAdminMode,
    setIsAdminMode: (state: AdminModeState) => state.setIsAdminMode,
};

// store
export const useAdminMode = create<AdminModeState>((set) => ({
    isAdminMode: false,
    setIsAdminMode: (val) => set(() => ({ isAdminMode: val })),
}));
