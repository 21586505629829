import { ISelectOption, MedSolSpace } from '@ms/medsol-components';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeading } from '../../components/PageHeading';
import { generateDocumentsRouteWithTravelerId } from '../../utilities/routing-utilities';
import { Button } from '../Button';
import { SelectInput } from '../SelectInput';
import { TextInput } from '../TextInput';

const TEST_TRAVELERS = [
    { label: 'Pick one!', value: '' },
    { label: 'NRaveler', value: '02c556c2-a722-eb11-a813-000d3a593813' },
    { label: 'A.Sabin', value: '3978a763-8538-e811-8115-3863bb3cf210' },
    { value: '5CBD6A21-5C52-EA11-A812-000D3A5937F3', label: 'tportalqa+1@gmail.com' },
    { value: '783effc3-e858-ea11-a811-000d3a593813', label: 'tportalqa+2@gmail.com' },
    { value: '9B7D5142-D4C2-EA11-A812-000D3A35ED4E', label: 'tportalqa+3@gmail.com' },
    { value: '68230164-D4C2-EA11-A812-000D3A35ED4E', label: 'tportalqa+4@gmail.com' },
    { value: '229D4C7D-D4C2-EA11-A812-000D3A35ED4E', label: 'tportalqa+5@gmail.com' },
    { value: 'D98F7A8F-D4C2-EA11-A812-000D3A35ED4E', label: 'tportalqa+6@gmail.com' },
    { value: 'A17EAEB4-95F2-EA11-A815-000D3A35ED4E', label: 'tportalqa+7@gmail.com' },
    { value: 'C6C85F36-9FF2-EA11-A815-000D3A35ED4E', label: 'tportalqa+8@gmail.com' },
    { value: '38538239-5b5e-eb11-a812-000d3a322823', label: 'tportalqa+9@gmail.com' },
    { value: '3150F610-E425-EB11-A813-000D3A35ED4E', label: 'tportalqa+11@gmail.com' },
    { value: '2F18F95A-E05C-EB11-A812-000D3A593813', label: 'tportalqa+12@gmail.com' },
    { value: '001D21E6-642E-EB11-A813-000D3A593813', label: 'tportalqa+14@gmail.com' },
    { value: '76543817-D133-EB11-A813-000D3A593813', label: 'tportalqa+15@gmail.com' },
    { value: '3204B7E8-8E36-EB11-A813-000D3A35ED4E', label: 'tportalqa+16@gmail.com' },
    { value: '0BBCB516-9336-EB11-A813-000D3A593813', label: 'tportalqa+18@gmail.com' },
    { value: 'D034CB2E-9336-EB11-A813-000D3A593813', label: 'tportalqa+19@gmail.com' },
    { value: '22E52641-9336-EB11-A813-000D3A593813', label: 'tportalqa+20@gmail.com' },
    { value: 'F4C5C280-C73F-EB11-A813-000D3A35ED4E', label: 'tportalqa+21@gmail.com' },
    { value: 'ED8FFE9F-C73F-EB11-A813-000D3A593813', label: 'tportalqa+22@gmail.com' },
    { value: '681746B8-C73F-EB11-A813-000D3A593813', label: 'tportalqa+23@gmail.com' },
    { value: 'F200C013-C83F-EB11-A813-000D3A35ED4E', label: 'tportalqa+24@gmail.com' },
    { value: '5dd5d02b-c83f-eb11-a813-000d3a35ed4e', label: 'tportalqa+25@gmail.com' },
    { value: '1CE9D840-C83F-EB11-A813-000D3A593813', label: 'tportalqa+26@gmail.com' },
    { value: '726C4F5A-C83F-EB11-A813-000D3A593813', label: 'tportalqa+27@gmail.com' },
    { value: '5199F473-C83F-EB11-A813-000D3A35ED4E', label: 'tportalqa+28@gmail.com' },
    { value: 'D189009E-CB3F-EB11-A813-000D3A35ED4E', label: 'tportalqa+29@gmail.com' },
    { value: 'a80f7992-cb5d-eb11-a812-000d3a322823', label: 'tportalqa+30@gmail.com' },
    { value: '1e67427c-4a32-ea11-a810-000d3a593c3f', label: 'tportalqa+31@gmail.com' },
    { value: 'f908e9dd-576a-eb11-a812-00224803a18c', label: 'tportalqa+32@gmail.com' },
    { value: 'a6924a97-876a-eb11-a812-00224803a18c', label: 'tportalqa+33@gmail.com' },
    { value: 'c503d407-c36a-eb11-a812-00224803a18c', label: 'tportalqa+34@gmail.com' },
    { value: '4e39fb98-6896-eb11-b1ac-0022480931a5', label: 'tportalqa+46@gmail.com' },
    { value: '258728ff-6996-eb11-b1ac-0022480931a5', label: 'tportalqa+47@gmail.com' },
    { value: '0d62fa15-6a96-eb11-b1ac-0022480931a5', label: 'tportalqa+48@gmail.com' },
];

const StyledTravelerInputDiv = styled.form`
    width: 100%;
    height: auto;
    padding: 16px;
    display: flex;
    flex-flow: column;
    border: 1px solid #969aa4;
    border-radius: 8px;
    background-color: white;
`;

export function ViewTravelerDocuments() {
    const [travelerID, setTravelerID] = React.useState('ed8ffe9f-c73f-eb11-a813-000d3a593813');
    const [selectValue, setSelectValue] = React.useState<ISelectOption | undefined>(undefined);

    function selectOnChange(newVal: ISelectOption) {
        setSelectValue(newVal);
    }

    const travelerOptions = TEST_TRAVELERS.map(({ label, value }) => ({ label, value }));

    return (
        <StyledTravelerInputDiv>
            <PageHeading>View Traveler Documents</PageHeading>
            <TextInput placeholder="Traveler ID" value={travelerID} onChange={(e) => setTravelerID(e.currentTarget.value)} />
            <MedSolSpace h={4} />
            <Button as={Link} to={generateDocumentsRouteWithTravelerId(travelerID)}>
                View Documents
            </Button>
            <MedSolSpace h={8} />
            {process.env.REACT_APP_SHOW_QA_TEST_USERS === 'true' && (
                <>
                    <p>or, select from this list. QA ONLY</p>
                    <SelectInput
                        name="traveler-select"
                        placeholder="Select Traveler"
                        status="default"
                        onChange={selectOnChange}
                        value={selectValue}
                        options={travelerOptions}
                    />

                    <MedSolSpace h={4} />
                </>
            )}
            <Button as={Link} to={generateDocumentsRouteWithTravelerId(selectValue?.value as string)} disabled={!selectValue}>
                View Documents from Select
            </Button>
        </StyledTravelerInputDiv>
    );
}
