import React from 'react';
import { Button } from '../Button';
import styled from 'styled-components';
import { MedSolSpace, ModalV2Header, ModalV2Footer, ModalV2Body, Typography } from '@ms/medsol-components';
import { Spinner } from 'reactstrap';
import { useTravelerId, useTravelerIdSelectors } from '../../hooks/useTravelerId';
import { usePadiDocumentsQuery } from '../../queries/usePadiDocumentsQuery';
import { ButtonPrimary } from '../ButtonPrimary';
import { useModal } from '../../hooks/useModal';

const MAX_UPLOAD_FILE_SIZE = 31457280;
const MAX_UPLOAD_FILE_SIZE_FRIENDLY_NAME = '30MB';
const ALLOWED_FILE_TYPES_FOR_UPLOAD = '.pdf,.png,.jpg,.doc,.docx,.bmp,';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
`;

const StyledActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export function ModalFullApplicationUpload() {
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = React.useState<null | File>(null);
    const { uploadFullApplicationMutation } = usePadiDocumentsQuery();
    const travelerId = useTravelerId(useTravelerIdSelectors.travelerId);
    const { closeModal } = useModal();

    function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
        if (!hiddenFileInput || !hiddenFileInput.current) return;

        //@ts-ignore - object is possibly null error
        hiddenFileInput.current.click();
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            if (e.currentTarget.files[0].size > MAX_UPLOAD_FILE_SIZE) {
                alert(`Max file size allowed: ${MAX_UPLOAD_FILE_SIZE_FRIENDLY_NAME}`);
            } else {
                setFile(e.currentTarget.files[0]);
            }
        }
    }

    return (
        <>
            <ModalV2Header hasDivider={false} onClose={closeModal}>
                <Typography variant="head4_strong">Upload Full Application</Typography>
            </ModalV2Header>

            {uploadFullApplicationMutation.isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner className="spinner" />
                </div>
            ) : (
                <ModalV2Body>
                    <StyledContainer>
                        {file && file.name ? (
                            <Typography variant="head4">{file.name}</Typography>
                        ) : (
                            <>
                                <Typography variant="p1">Accepted Files: pdf | .png | .jpg | .doc | .docx | .bmp</Typography>
                                <Typography variant="p1">(Max file size: 30MB)</Typography>
                            </>
                        )}
                        <MedSolSpace h={4} />

                        <StyledActionsContainer>
                            {/* @ts-ignore */}
                            <Button onClick={handleClick}>Browse Files</Button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                accept={ALLOWED_FILE_TYPES_FOR_UPLOAD}
                                name="uploadFile"
                                id="uploadFile"
                                style={{ display: 'none' }}
                                onChange={handleFile}
                            />
                        </StyledActionsContainer>
                        <MedSolSpace h={4} />
                    </StyledContainer>
                    <ModalV2Footer>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                            <ButtonPrimary
                                onClick={() => {
                                    if (file) {
                                        uploadFullApplicationMutation.mutate({ travelerId, payload: { file } });
                                    }
                                }}
                                disabled={!file}
                            >
                                Upload
                            </ButtonPrimary>
                        </div>
                    </ModalV2Footer>
                </ModalV2Body>
            )}
        </>
    );
}
