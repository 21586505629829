import React from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/auth-config';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export function useAuth() {
    // get azure instance and account
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    // function which attempts to generate access token
    const getAccessToken = React.useCallback(
        async (onAfter: (token: any) => void) => {
            account &&
                (await instance.acquireTokenSilent({ ...loginRequest, account }).then(
                    (response: any) => {
                        // run callback func
                        onAfter(response.accessToken);
                    },
                    (error: any) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            // fallback to interaction when silent call fails
                            return instance.acquireTokenRedirect({ ...loginRequest, account });
                        }
                    }
                ));
        },
        [instance, account]
    );

    return React.useMemo(
        () => ({
            account,
            getAccessToken,
        }),
        [getAccessToken, account]
    );
}
