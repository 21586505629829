import React from 'react';
import create, { State } from 'zustand';
import { ModalFullApplicationUpload } from '../components/ModalFullApplicationUpload';
import { IModalResetDocumentsProps, ModalResetDocuments } from '../components/ModalResetDocuments';
import { IModalUploadRequirementProps, ModalUploadRequirement } from '../components/ModalUploadRequirement';
import { IModalDeleteDocumentProps, ModalDeleteDocument } from '../components/ModalDeleteDocument';

interface IModals {
    resetDocuments: (props: IModalResetDocumentsProps) => JSX.Element;
    uploadRequirement: (props: IModalUploadRequirementProps) => JSX.Element;
    uploadFullApplication: () => React.ReactNode;
    deleteDocument: (props: IModalDeleteDocumentProps) => JSX.Element;
    '': () => '';
}

type ModalOptions = keyof IModals;

const Modals: IModals = {
    resetDocuments: ModalResetDocuments,
    uploadFullApplication: ModalFullApplicationUpload,
    uploadRequirement: ModalUploadRequirement,
    deleteDocument: ModalDeleteDocument,
    '': () => '',
};

export function getModalContent(key: ModalOptions) {
    return Modals[key];
}

interface ModalState extends State {
    isOpen: boolean;
    size: 'sm' | 'md' | 'lg';
    modalKey: ModalOptions;
    modalProps: unknown;
    setModalKey: (key: ModalOptions) => void;
    openModal: (key?: ModalOptions) => void;
    closeModal: () => void;
    getContent: () => void;
    openResetDocumentModal: (props: IModalResetDocumentsProps) => void;
    openUploadRequirementModal: (props: IModalUploadRequirementProps) => void;
    openDeleteDocumentModal: (props: IModalDeleteDocumentProps) => void;
}

// store
export const useModal = create<ModalState>((set, get) => ({
    isOpen: false,
    size: 'md',
    modalKey: '',
    modalProps: {},
    setModalKey: (key: ModalOptions) => set(() => ({ modalKey: key })),
    openModal: (key?: ModalOptions) =>
        set(() => {
            const newObj = { isOpen: true };

            if (key) {
                // @ts-ignore
                newObj.modalKey = key;
            }
            return newObj;
        }),
    closeModal: () => set(() => ({ isOpen: false, modalKey: '', modalProps: {} })),
    getContent: () => Modals[get().modalKey],
    openResetDocumentModal: (props: IModalResetDocumentsProps) =>
        set(() => ({
            isOpen: true,
            modalKey: 'resetDocuments',
            modalProps: props,
        })),
    openUploadRequirementModal: (props: IModalUploadRequirementProps) =>
        set(() => ({
            isOpen: true,
            modalKey: 'uploadRequirement',
            modalProps: props,
        })),
    openDeleteDocumentModal: (props: IModalDeleteDocumentProps) =>
        set(() => ({
            isOpen: true,
            modalKey: 'deleteDocument',
            modalProps: props,
        })),
}));

// selectors
// export const useModalSelectors = {
//     isModalOpen: (state: ModalState) => state.isModalOpen,
//     modalKey: (state: ModalState) => state.modalKey,
//     setModalKey: (state: ModalState) => state.setModalKey,
//     openModal: (state: ModalState) => state.openModal,
//     closeModal: (state: ModalState) => state.closeModal,
//     getModalContent: (state: ModalState) => state.getContent,
// };
