import create, { State } from 'zustand';

/* Experimenting with Zustand. Really neat having global state without any providers. It just works. */

interface TravelerIdState extends State {
    travelerId: string;
    setTravelerId: (pTravelerId: string) => void;
}

// selectors
export const useTravelerIdSelectors = {
    travelerId: (state: TravelerIdState) => state.travelerId,
    setTravelerId: (state: TravelerIdState) => state.setTravelerId,
};

// store
export const useTravelerId = create<TravelerIdState>((set) => ({
    travelerId: '',
    setTravelerId: (pTravelerId) => set((state) => ({ travelerId: pTravelerId })),
}));
