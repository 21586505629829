import React from 'react';
import { MedSolModalV2 } from '@ms/medsol-components';
import { getModalContent, useModal } from '../../hooks/useModal';

export function Modal() {
    const { size, isOpen, closeModal, modalKey, modalProps } = useModal();

    //@ts-ignore - complicated typing issue here for dynamic typescript content generation with dynamic props (same functionality as TP)
    const Content = React.createElement(getModalContent(modalKey), modalProps);

    return <MedSolModalV2 isOpen={isOpen} onDismiss={closeModal} size={size} content={Content} />;
}
