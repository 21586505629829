import React from 'react';
import { MedSolIcon } from '@ms/medsol-components';
import styled from 'styled-components';

const StyledBackButton = styled.div`
    position: absolute;
    top: 4px;
    left: 12px;
    z-index: 2;
    cursor: pointer;
`;

export function DrawerBackButton({ onClick }: { onClick: () => void }) {
    return (
        <StyledBackButton onClick={onClick}>
            <MedSolIcon name="ArrowLeft" color={'#4B4D52'} />
        </StyledBackButton>
    );
}
