import React from 'react';
import { MedSolButton, MedSolSpace, Typography } from '@ms/medsol-components';
import { useGetCertificationQuery } from '../hooks';
import { PadiDrawerContent, PadiDrawerFooter, PadiDrawerHeader } from '../../../../components/PadiDrawer';
import { Image, StyledImageContainer, StyledImagesContainer } from '../../../../components/Image';
import { ExpirationStatusEnum } from '@ms/credentials/dist/models/expiration-status';
import { DrawerBackButton } from '../../../../components/PadiDrawer/_/DrawerBackButton';

import { CertificationForm } from './CertificationForm';
import { CertificationLogic } from '../logic';
import { Button } from '../../../../components/Button';
import { useModal } from '../../../../hooks/useModal';
import { useMicrosoftGraphUserGroups } from '../../../../queries/useMicrosoftGraphUserGroups';
import { useTravelerId } from '../../../../hooks/useTravelerId';
import { useDeleteDocumentQuery } from '../../../../queries/useDeleteDocumentQuery';
import { StyledButtonFooter } from '../../../../components/DrawerDocumentDetails/styles';

type CertificationDetailsDrawerState = 'viewing' | 'editing';

export function CertificationDetailsDrawer({ certificationId }: { certificationId: string }) {
    const [drawerState, setDrawerState] = React.useState<CertificationDetailsDrawerState>('viewing');
    const { data: certification, status: certificationStatus } = useGetCertificationQuery(certificationId);
    const { openDeleteDocumentModal } = useModal();
    const deleteDocumentMutation = useDeleteDocumentQuery();
    const { travelerId } = useTravelerId();
    const { hasDeletePermission } = useMicrosoftGraphUserGroups();
    const documentId = certification?.frontSideBlobId || (certification?.backSideBlobId as string);

    // automatically open the edit drawer when the certification is expired and there is no expiration date (meaning its incomplete from PULSE probably)
    React.useEffect(() => {
        if (certification?.status === ExpirationStatusEnum.Expired && certification.expirationDate === null) {
            setDrawerState('editing');
        }
    }, [certification]);

    return (
        <>
            <PadiDrawerHeader
                title={CertificationLogic.getFormattedTitle(certification?.certificationType)}
                subtitle={CertificationLogic.getStatusDescription(
                    certification?.isComplete,
                    certification?.expirationDate || 0,
                    certification?.status || ExpirationStatusEnum.Expired
                )}
            >
                {drawerState !== 'viewing' && <DrawerBackButton onClick={() => setDrawerState('viewing')} />}
            </PadiDrawerHeader>

            {drawerState === 'editing' && certification !== undefined && (
                <CertificationForm certification={CertificationLogic.convertCertificationModelToCertificationForm(certification)} isActive={true} />
            )}
            {drawerState === 'viewing' && (
                <>
                    <PadiDrawerContent>
                        {certificationStatus === 'loading' && (
                            <Typography variant="p1" color="Gray_500">
                                Loading certification details...
                            </Typography>
                        )}
                        {certificationStatus === 'error' && (
                            <Typography variant="p1" color="Gray_500">
                                Error loading certification details...
                            </Typography>
                        )}
                        {certificationStatus === 'success' && (
                            <StyledImagesContainer>
                                {certification?.frontSideUri && (
                                    <StyledImageContainer>
                                        <Typography variant="p1" color="Gray_500">
                                            Front
                                        </Typography>
                                        <Image width="350px" src={certification?.frontSideUri} alt="License Front Side" />
                                    </StyledImageContainer>
                                )}
                                {certification?.backSideUri && (
                                    <StyledImageContainer>
                                        <MedSolSpace h={6} />
                                        <Typography variant="p1" color="Gray_500">
                                            Back
                                        </Typography>
                                        <Image width="350px" src={certification?.backSideUri} alt="License Back Side" />
                                    </StyledImageContainer>
                                )}
                            </StyledImagesContainer>
                        )}
                    </PadiDrawerContent>
                    <PadiDrawerFooter>
                        <StyledButtonFooter>
                            {/* only show delete button if doucmentId is present. */}
                            {!!documentId && process.env.REACT_APP_SHOW_DELETE_MODAL === 'true' && hasDeletePermission ? (
                                <Button
                                    variant="danger"
                                    onClick={() => {
                                        openDeleteDocumentModal({ documentId, travelerId, deleteDocumentMutation });
                                    }}
                                >
                                    Delete
                                </Button>
                            ) : null}
                            <MedSolButton variant="secondary" onClick={() => setDrawerState('editing')}>
                                Edit
                            </MedSolButton>
                        </StyledButtonFooter>
                    </PadiDrawerFooter>
                </>
            )}
        </>
    );
}
