import React from 'react';
import { StyledDrawerContent, StyledDrawerFooter } from '../PadiDrawer/styles';
import styled from 'styled-components';
import { MedSolSpace } from '@ms/medsol-components';
import { Checkbox } from '../Checkbox';
import { AuditLogActions, PadiDocumentDTOExtended, Status, StatusExtended } from '../../models/app-types';
import { useTravelerId } from '../../hooks/useTravelerId';
import { useTravelerProfile } from '../../queries/useTravelerProfile';
import { useAuditLogAction } from '../../queries/useAuditLogAction';
import { LinkButton } from '../LinkButton';
import { Spinner } from '../Spinner';
import { usePadiDocumentsQuery } from '../../queries/usePadiDocumentsQuery';
import { formatDate, sortTasksByName } from '../../utilities/app-utilities';
import { ButtonPrimary } from '../ButtonPrimary';
import { PadiDrawerHeader } from '../PadiDrawer';
import { SendAuditLogPayload } from '../../services/PadiService';

const StyledUl = styled.ul`
    width: 100%;
`;

const StyledLi = styled.li`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${(props) => props.theme.spacing(4)};
    font-size: 16px;

    span {
        line-height: 24px;
    }
`;

const StyledSpinner = styled(Spinner)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;

    div {
        height: 24px;
        width: 24px;
        margin: 0;
        border-width: 2px;
    }
`;

function isDocumentSelected(selectedDocuments: PadiDocumentDTOExtended[], document: PadiDocumentDTOExtended): boolean {
    if (!selectedDocuments || !selectedDocuments.length) return false;

    return selectedDocuments.map((doc) => doc.taskProps?.id).includes(document.taskProps?.id);
}

export function DrawerDownloadDocuments() {
    const { data, downloadRequirementsMutation } = usePadiDocumentsQuery();
    const { travelerId } = useTravelerId();
    const { travelerFullName } = useTravelerProfile();
    const { sendAuditLogAction } = useAuditLogAction();
    const downloadableDocuments = React.useMemo(
        () =>
            data.filter((datum: PadiDocumentDTOExtended) => {
                return (
                    datum.isDownloadable && // document is downloadable
                    !datum.taskProps?.isLocked && // document is not locked
                    datum.taskProps?.status === Status.Done // document in done status
                );
            }),
        [data]
    );
    const [selectedDocuments, setSelectedDocuments] = React.useState<PadiDocumentDTOExtended[]>([]);

    // initialize with all docs selected
    React.useEffect(() => {
        setSelectedDocuments(downloadableDocuments);
    }, [downloadableDocuments]);

    function selectAllDocuments() {
        setSelectedDocuments(downloadableDocuments);
    }

    function deselectAllDocuments() {
        setSelectedDocuments([]);
    }

    function handleToggleDocument(document: PadiDocumentDTOExtended) {
        if (isDocumentSelected(selectedDocuments, document)) {
            setSelectedDocuments((prev) => prev.filter((datum) => datum.taskProps?.id !== document.taskProps?.id));
        } else {
            setSelectedDocuments((prev) => [...prev, document]);
        }
    }

    function handleDownload() {
        const formattedPayload = {
            travelerId: travelerId,
            blobDetails: selectedDocuments.map((doc) => ({
                uri: doc.latestDocumentUri,
                fileName: doc.fileName,
                createdDate: doc.latestDocumentCreatedDate,
            })),
        };

        const auditLogPayload: SendAuditLogPayload[] = selectedDocuments.map((doc) => ({
            action: AuditLogActions.Viewed,
            fileName: doc.fileName,
            documentName: doc.name,
            documentId: doc.storageId,
            requirementId: doc.resetId,
        }));

        downloadRequirementsMutation.mutate(formattedPayload, {
            onSuccess: (data: any) => {
                const file = new Blob([data], { type: 'application/x-zip-compressed' });
                let url = window.URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${travelerFullName.replace(' ', '')}-${formatDate(new Date())}.zip`;
                a.click();
            },
            onError: () => {
                alert('Oops, there was an error creating the zip file. One of the files you selected is playing hard to get.');
                console.log('Error submitting with this payload', formattedPayload);
            },
        });
        sendAuditLogAction(auditLogPayload); //send view action log to BE
    }

    return (
        <>
            <PadiDrawerHeader title="Select Documents">
                <div style={{ display: 'flex' }}>
                    <LinkButton size={'sm'} onClick={selectAllDocuments}>
                        Select All
                    </LinkButton>
                    <LinkButton size={'sm'} onClick={deselectAllDocuments}>
                        Deselect All
                    </LinkButton>
                </div>
            </PadiDrawerHeader>
            <StyledDrawerContent>
                <MedSolSpace h={4} />
                {downloadableDocuments && downloadableDocuments.length ? (
                    <StyledUl id="downloadable-document-list">
                        {sortTasksByName(downloadableDocuments, 'name', StatusExtended.Asc).map((document: PadiDocumentDTOExtended, index: number) => (
                            <>
                                <StyledLi data-qa-index={index}>
                                    <Checkbox
                                        checked={isDocumentSelected(selectedDocuments, document)}
                                        key={document.taskProps?.id}
                                        handleCheckboxChange={() => handleToggleDocument(document)}
                                    />
                                    <span>{document.taskProps?.title}</span>
                                </StyledLi>
                                <MedSolSpace h={4} />
                            </>
                        ))}
                    </StyledUl>
                ) : null}
            </StyledDrawerContent>
            <StyledDrawerFooter style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <ButtonPrimary disabled={!selectedDocuments.length || downloadRequirementsMutation.isLoading} onClick={handleDownload}>
                    {downloadRequirementsMutation.isLoading ? <StyledSpinner variant="light" /> : `Download ${selectedDocuments.length}`}
                </ButtonPrimary>
            </StyledDrawerFooter>
        </>
    );
}
