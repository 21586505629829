import { TIconOptions, MedSolTheme, MedSolIconOptions } from '@ms/medsol-components';
import { Status, ITaskCardPropsExtended, PadiDocumentDTO, PadiRequirementType, RequirementFormField, StatusExtended } from '../models/app-types';
import { DateTime } from 'luxon';
import { PadiEndpoints } from '../constants/api-constants';
import { CMADPermissionOverridableDocs } from '../constants/app-constants';
import { generateDocumentDescription, generatePADIDocumentDescription } from './task-card-utilities';

export function generateDocumentsAPIWithTravelerId(travelerId = '') {
    if (!travelerId) return '';

    return PadiEndpoints.documents.replace(':travelerId', travelerId);
}

export function generatePadiDocumentsAPIWithTravelerId(travelerId = '') {
    if (!travelerId) return '';

    return PadiEndpoints.padiDocuments.replace(':travelerId', travelerId);
}

export function generateProfileAPIWithTravelerId(travelerId = '') {
    if (!travelerId) return '';

    return PadiEndpoints.profile.replace(':travelerId', travelerId);
}

export function generateDeleteDocumentAPIWithTravelerIdAndDocumentId(travelerId = '', documentId = '', deleteReason = 0) {
    if (!travelerId || !documentId || !deleteReason) return '';

    return PadiEndpoints.deleteDocument
        .replace(':travelerId', travelerId)
        .replace(':documentId', documentId)
        .concat('/' + deleteReason.toString());
}

export function generateResetDocumentAPIWithTravelerIdAndRequirementId(travelerId = '', requirementId = '') {
    if (!travelerId || !requirementId) return '';

    return PadiEndpoints.resetDocument.replace(':travelerId', travelerId).replace(':requirementId', requirementId);
}

export function generateRequirementHistoryAPIWithTravelerIdAndRequirementId(travelerId = '', requirementId = '') {
    if (!travelerId || !requirementId) return '';

    return PadiEndpoints.requirementHistoryUris.replace(':travelerId', travelerId).replace(':requirementId', requirementId);
}

export function generateAuditLogAPIUrl() {
    return PadiEndpoints.auditLog;
}

export function generateFullApplicationUploadAPI(travelerId = '') {
    if (!travelerId) return '';

    return PadiEndpoints.fullApplicationUpload.replace(':travelerId', travelerId);
}

export function generateRequirementUploadAPIUrl(travelerId = '', requirementId = '') {
    if (!travelerId || !requirementId) return '';

    return PadiEndpoints.uploadRequirement.replace(':travelerId', travelerId).replace(':requirementId', requirementId);
}

export function generateSpeicalDocumentUploadAPIUrl() {
    return PadiEndpoints.uploadSpecialDocument;
}

export function generateCertificationURL(travelerId = '', certificationId = '') {
    if (!travelerId) return '';
    if (travelerId !== '' && certificationId !== '')
        return PadiEndpoints.certification.replace(':travelerId', travelerId).replace(':certificationId', certificationId);

    return PadiEndpoints.certifications.replace(':travelerId', travelerId);
}

export function generateLicenseURL(travelerId = '', licenseId = '') {
    if (!travelerId) return '';
    if (travelerId !== '' && licenseId !== '') return PadiEndpoints.license.replace(':travelerId', travelerId).replace(':licenseId', licenseId);

    return PadiEndpoints.licenses.replace(':travelerId', travelerId);
}

function getIsFullApplicationDocument(requirementType?: PadiRequirementType): boolean {
    if (!requirementType) return false;

    return requirementType === 'DocumentLibraryFullApplication';
}

function getIsFullApplicationAdminModifiable(requirementType?: PadiRequirementType, status?: Status): boolean {
    return getIsFullApplicationDocument(requirementType) && status !== Status.Done;
}

export function generateTaskPropsFromPadiDocuments(
    documentInfo: PadiDocumentDTO,
    selectedDocumentId: string,
    hasADPermission: boolean,
    hasCMADPermission: boolean
): ITaskCardPropsExtended {
    const id = `${documentInfo.resetId}-${documentInfo.name}`;

    // documentHasHistory basically just means the document has been completed. So, combine the latest info with documentHistory if records exists
    const documentHistory = documentInfo.documentHasHistory
        ? [
              ...(documentInfo.documentHistory || []),
              { uri: documentInfo.latestDocumentUri, createdDateTime: documentInfo.latestDocumentCreatedDate, documentId: documentInfo.storageId },
          ]
        : [];

    // sort the history records by created date
    const sortedDocumentHistory = documentHistory.sort(
        (a: any, b: any) => Math.abs(new Date(b.createdDateTime).getTime()) - Math.abs(new Date(a.createdDateTime).getTime())
    );

    // is document unlocked by CM AD permission
    const isDocumentCMUnLocked = CMADPermissionOverridableDocs.includes(documentInfo.name) && hasCMADPermission;

    return {
        id,
        status: documentInfo.status,
        title: documentInfo.name,
        isSensitive: documentInfo.isSensitive,
        expiredDate: '',
        hasHistory: documentInfo.documentHasHistory,
        isResettable: documentInfo.isResettable,
        documentType: documentInfo.documentType,
        requirementType: documentInfo.requirementTypeId,
        retentionType: documentInfo.retentionType,
        downloadUrl: '',
        dealNumber: documentInfo.dealNumber,
        startDate: documentInfo.startDate,
        isGlobal: documentInfo.isGlobal,
        statusDescription: generateStatusDescText(documentInfo.status, formatDate(documentInfo.latestDocumentCreatedDate || '')),
        documentDescription: generateDocumentDescription(generatePADIDocumentDescription(documentInfo)),
        // if not cm unlocked and the document is sensitive and the current user doesnt have admin ad permission
        isLocked: !isDocumentCMUnLocked && documentInfo.isSensitive && !hasADPermission,
        createdDate: documentInfo.latestDocumentCreatedDate || '',
        iconName: generateTaskStatusIcon(documentInfo.status),
        isSelected: id === selectedDocumentId,
        isFullApplicationAdminModifiable: getIsFullApplicationAdminModifiable(documentInfo.requirementType, documentInfo.status),
        documentHistory: sortedDocumentHistory,
        viewableUri: documentInfo.latestDocumentUri || '',
        source: 'padi',
        resetId: documentInfo.resetId,
    };
}

export function formatDate(date: Date | number | string | undefined, format: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT) {
    return date ? DateTime.fromJSDate(new Date(date)).toLocaleString(format) : '';
}

function getIsValidDateString(date: Date | string | undefined) {
    if (!date) return false;

    if (date === '1/1/1') return false;

    return true;
}

export function generateTaskStatusIconColor(status: Status | undefined): string {
    switch (status) {
        case Status.New:
            return MedSolTheme.colors.Error;
        case Status.Done:
            return MedSolTheme.colors.Success;
        case Status.Action:
            return MedSolTheme.colors.Error;
        case Status.InProgress:
            return MedSolTheme.colors.Error;
        default:
            return MedSolTheme.colors.Onyx;
    }
}

function generateStatusDescText(status: Status | undefined, dateCompleted: string | undefined): string {
    switch (status) {
        case Status.New:
            return 'Incomplete';
        case Status.InProgress:
            return 'In Progress';
        case Status.Action:
            return 'Action Needed';
        case Status.Processing: //Added on 10/30/2023 to support docuSign flow and its temporary status(lasts about 20-30 secs only)
            return 'Processing';
        case Status.Pending:
            return 'Pending';
        case Status.Done:
            return getIsValidDateString(dateCompleted) ? `Completed: ${dateCompleted}` : 'Completed';
        default:
            return 'Incomplete';
    }
}

export function generateTaskStatusIcon(status: Status | undefined): TIconOptions {
    switch (status) {
        case Status.New:
            return MedSolIconOptions.NotificationCircle;
        case Status.Done:
            return MedSolIconOptions.CheckmarkCircle;
        case Status.Action:
            return MedSolIconOptions.Warning;
        case Status.InProgress:
            return MedSolIconOptions.Clock;
        case Status.Processing: //Added on 10/30/2023 to support docuSign flow and its temporary status(lasts about 20-30 secs only)
            return MedSolIconOptions.ProcessingCircle;
        case Status.Pending:
            return MedSolIconOptions.Warning;
        default:
            return MedSolIconOptions.VerticalDots;
    }
}

//return sort by descendeing if 'desc' is passed otherwise default to ascending
export function sortTasksByName<T extends Record<string, any>>(data: T[], key: string, order?: string): T[] {
    if (!data) return [];
    if (order === StatusExtended.Desc) {
        return data.sort((a: T, b: T) => {
            const aName = typeof a[key] === 'string' ? a[key].trim().toLowerCase() : '';
            const bName = typeof b[key] === 'string' ? b[key].trim().toLowerCase() : '';

            if (bName > aName) {
                return 1;
            }
            if (aName > bName) {
                return -1;
            }

            return 0;
        });
    }

    return data.sort((a: T, b: T) => {
        const aName = typeof a[key] === 'string' ? a[key].trim().toLowerCase() : '';
        const bName = typeof b[key] === 'string' ? b[key].trim().toLowerCase() : '';

        if (aName > bName) {
            return 1;
        }
        if (bName > aName) {
            return -1;
        }

        return 0;
    });
}

function sortTasksByStatus(data: ITaskCardPropsExtended[], order?: number): ITaskCardPropsExtended[] {
    //return incomplete docs first by default unless user choose to sort by completed first.
    const displayOrderPending = data.filter(({ status }) => status === Status.Pending);
    const displayOrder1 = data.filter(({ status }) => status === Status.Action);
    const displayOrder2 = data.filter(({ status }) => status === Status.InProgress || status === Status.New || status === Status.Processing);
    const displayOrder3 = data.filter(({ status }) => status === Status.Done);

    if (order === StatusExtended.Done) {
        return [...displayOrder3, ...displayOrder2, ...displayOrder1, ...displayOrderPending];
    }

    return [...displayOrderPending, ...displayOrder1, ...displayOrder2, ...displayOrder3];
}

export function sortTasksByNameAndStatus(data: ITaskCardPropsExtended[], order?: any): any[] {
    if (!data) return [];
    let sortedDataByName = sortTasksByName(data, 'title', order);
    if (order === StatusExtended.Done || order === StatusExtended.New) {
        sortedDataByName = sortTasksByStatus(sortedDataByName, order);
    }
    return sortedDataByName;
}

export function formatPhoneNumberFromString(phoneString: string): string {
    if (!phoneString) return '';

    const cleaned = ('' + phoneString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return '';
}

export function mapRequirementFormValuesForUpload(fields: RequirementFormField[] | null | undefined, form: Record<string, any>) {
    if (!fields) {
        return [];
    }

    return fields.map((field) => {
        const matchedFormField = form[field.name];

        if (matchedFormField) {
            if (field.type === 'Date') {
                return { ...field, value: DateTime.fromISO(form[field.name]).toFormat('MM/dd/yyyy') };
            }

            return { ...field, value: form[field.name] };
        }
        return { ...field };
    });
}

export const DATE_FORMAT_REGEX = {
    'MM/DD/YYYY': /^\d{2}\/?\d{2}\/?\d{4}$/,
    'YYYY/MM/DD': /^\d{4}\/?\d{2}\/?\d{2}$/,
    'YYYY-MM-DD': /^\d{4}-?\d{2}-?\d{2}$/,
    'MM/YYYY': /^\d{2}\/?\d{4}$/,
};

//only show either completed or incomplete with document history perm tax form with extension type deal
export const removeIncompletePermTaxFormWithExtension = (documents: ITaskCardPropsExtended[]) => {
    return (documents || []).filter((task: ITaskCardPropsExtended) => {
        if (
            task.title?.split(' ').join('').toLocaleLowerCase() === 'permanenttaxresidentnotification' &&
            task.retentionType?.toLowerCase() === 'extension' &&
            task?.status === 1
        ) {
            return task?.viewableUri.length > 0;
        } else {
            return task;
        }
    });
};
