export const PADI_BASE_URL = `${process.env.REACT_APP_PADI_BASE_URL}`;
export const GRAPH_BASE_URL = 'https://graph.microsoft.com/v1.0';
export const USE_AUDIT_LOG_INFO = `${process.env.REACT_APP_USE_AUDIT_LOG_INFO}`;

export enum PadiEndpoints {
    documents = '/v2/travelers/:travelerId/docs',
    document = '/v1/travelers/:travelerId/document/:documentId',
    profile = '/v1/travelers/:travelerId/profile',
    deleteDocument = '/v1/travelers/:travelerId/documents/:documentId',
    resetDocument = '/v1/travelers/:travelerId/resetrequirement/:requirementId',
    fullApplicationUpload = '/v1/travelers/:travelerId/UploadFullApplicationDoc',
    requirementHistoryUris = '/v1/travelers/:travelerId/GetRequirementHistoryUris/:requirementId',
    auditLog = '/v1/travelers/auditlog',
    downloadRequirements = '/v1/traveler/downloadrequirement',
    padiDocuments = '/v1/traveler/GetPadiDocs/:travelerId',
    uploadRequirement = '/v1/traveler/saverequirement/:travelerId/:requirementId',
    uploadSpecialDocument = '/v1/traveler/specialDocuments',
    certifications = '/v1/travelers/:travelerId/certifications',
    certification = '/v1/travelers/:travelerId/certifications/:certificationId',
    licenses = '/v1/travelers/:travelerId/licenses',
    license = '/v1/travelers/:travelerId/licenses/:licenseId',
    metaData = '/v1/travelers/metadata/profile',
}

// Add here the endpoints for MS Graph API services you would like to use.
export enum GraphEndpoints {
    Me = '/me',
    MemberOf = '/users/{:id}/memberOf',
    Groups = '/getMemberGroups',
}
