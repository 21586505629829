import { useDocumentOrderByFilter, useOrderBySelector } from '../../hooks/useDocumentsOrderByFilter';
import { OrderByOptions } from '../../models/app-types';
import { ClickOutSideWrapper } from '../ClickOutSideWrapper';
import {
    StyledFiltersButton,
    StyledFiltersDropDown,
    StyledMedSolIcon,
    StyledFiltersDropdownWrapper,
    StyledFiltersSelectContainer,
    StyledSortBy,
    StyledSortByLabel,
    StyledSortByTitle,
} from './styles';

export function OrderByDropDown() {
    const { setDisplayName, setOrder, setIsOrderByDropdownOpen, closeOrderByDropdown } = useDocumentOrderByFilter();
    const displayName = useDocumentOrderByFilter(useOrderBySelector.displayName);
    const isOrderByDropdownOpen = useDocumentOrderByFilter(useOrderBySelector.isOrderByDropdownOpen);

    const handleClickOutside = () => closeOrderByDropdown();

    return (
        <ClickOutSideWrapper onClose={handleClickOutside}>
            <StyledFiltersSelectContainer>
                <StyledSortBy>
                    <StyledSortByLabel>Sort By</StyledSortByLabel>
                    <StyledSortByTitle> {displayName}</StyledSortByTitle>
                </StyledSortBy>

                <StyledFiltersDropdownWrapper onClick={setIsOrderByDropdownOpen}>
                    <StyledMedSolIcon name={'CompareArrows'} color="black" margin="4px 0 0 8px" />
                    <StyledFiltersDropDown show={isOrderByDropdownOpen}>
                        <StyledSortByLabel>Sort By</StyledSortByLabel>
                        {OrderByOptions.map((order, i) => (
                            <StyledFiltersButton
                                key={i}
                                onClick={() => {
                                    setOrder(order.value);
                                    setDisplayName(order.title);
                                }}
                            >
                                {order.title}
                            </StyledFiltersButton>
                        ))}
                    </StyledFiltersDropDown>
                </StyledFiltersDropdownWrapper>
            </StyledFiltersSelectContainer>
        </ClickOutSideWrapper>
    );
}
