import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { MedSolIcon, MedSolSpace, MedSolTheme } from '@ms/medsol-components';

const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox', readOnly: true })`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledIcon = styled(MedSolIcon)``;

const StyledCheckbox = styled.div<{ checked: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.theme.spacing(6)};
    height: ${(props) => props.theme.spacing(6)};
    border: ${(props) => `2px solid ${props.theme.colors.Onyx}`};
    //background: ${(props) => (props.checked ? 'red' : 'green')};
    border-radius: 3px;
    transition: all 150ms;

    ${StyledHiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }

    ${StyledIcon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;

const StyledCheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const StyledLabel = styled.label`
    margin: 0;
    padding: 0;
    display: flex;
`;

interface ICheckboxProps {
    checked: boolean;
    handleCheckboxChange?: any;
    label?: string;
}

export function Checkbox({ checked = true, handleCheckboxChange, label = '' }: ICheckboxProps) {
    function handleClick(e: MouseEvent<HTMLLabelElement, MouseEvent>) {
        e.preventDefault();
        handleCheckboxChange?.();
    }

    return (
        // @ts-ignore - ignoring ts issue with label onclicks
        <StyledLabel onClick={handleClick}>
            <StyledCheckboxContainer>
                <StyledHiddenCheckbox checked={checked} data-qa-checked={checked} />
                <StyledCheckbox checked={checked}>
                    <StyledIcon name="Check" color={MedSolTheme.colors.SincereSlate} />
                </StyledCheckbox>
            </StyledCheckboxContainer>
            {label && (
                <>
                    <MedSolSpace w={2} />
                    <span>{label}</span>
                </>
            )}
        </StyledLabel>
    );
}
