import { useMutation, useQueryClient } from 'react-query';
import { generatePadiDocumentsAPIWithTravelerId } from '../utilities/app-utilities';
import { useApi } from '../contexts/api-context';
import { useModal } from '../hooks/useModal';
import { useTravelerId } from '../hooks/useTravelerId';
import { useDrawer } from '../hooks/useDrawer';

export function usePadiUploadRequirementMutation() {
    const { PadiAPI } = useApi();
    const { travelerId } = useTravelerId();
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { closeDrawer } = useDrawer();

    //@ts-ignore - complex typescript typing issue
    return useMutation(PadiAPI?.uploadRequirement, {
        enabled: !!PadiAPI && travelerId,
        onSuccess: () => {
            closeDrawer();
            closeModal();
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(generatePadiDocumentsAPIWithTravelerId(travelerId)); // set documents to be refetched after a successful reset
        },
    });
}


