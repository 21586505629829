import { OrderBy, StatusExtended } from '../models/app-types';


import create, { State } from 'zustand';

/* Experimenting with Zustand. Really neat having global state without any providers. It just works. */

interface OrderByState extends State {
    isOrderByDropdownOpen: boolean;
    order: number | string;
    displayName: string
    setIsOrderByDropdownOpen: () => void;
    setOrder: (order: number | string) => void;
    setDisplayName: (name: string) => void;
    closeOrderByDropdown: () => void;
}

// selectors
export const useOrderBySelector = {
    isOrderByDropdownOpen: (state: OrderByState) => state.isOrderByDropdownOpen,
    order: (state: OrderByState) => state.order,
    displayName: (state: OrderByState) => state.displayName,
};

// store
export const useDocumentOrderByFilter = create<OrderByState>((set) => ({
    isOrderByDropdownOpen: false,
    order: StatusExtended.New,
    displayName: OrderBy.Default,
    setIsOrderByDropdownOpen: () => set((state) => ({ isOrderByDropdownOpen: !state.isOrderByDropdownOpen })),
    setOrder: (order) => set((state) => ({ order: order })),
    setDisplayName: (name) => set((state) => ({ displayName: name })),
    closeOrderByDropdown: () => set((state) => ({ isOrderByDropdownOpen: false }))
}));
