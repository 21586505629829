import styled from 'styled-components';
import { MedSolTheme, Typography } from '@ms/medsol-components';

export const StyledDrawerContainer = styled.div`
    height: calc(100vh - 58px); // height of action bar
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 8px auto 1fr 72px;
    grid-template-areas:
        'lightbar'
        'header'
        'content'
        'footer';
    overflow: hidden;
`;

export const StyledDrawerLightBar = styled.div`
    grid-area: lightbar;
    background: ${MedSolTheme.gradients['btn-blue']};
`;

export const StyledDrawerHeaderTitle = styled(Typography)`
    @media (${(props) => props.theme.breakpoints.sm}) {
        font-size: 28px;
    }
`;

export const StyledDrawerContent = styled.div`
    padding: ${(props) => `0 ${props.theme.spacing(6)} `};
    overflow: auto;
`;

export const StyledDrawerFooter = styled.div`
    padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(6)} `};
`;

export const StyledDrawerHeaderNew = styled.header`
    grid-area: header;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: ${(props) => `${props.theme.spacing(10)} ${props.theme.spacing(6)} 0 `};
    position: relative;
`;

export const StyledDrawerContentNew = styled.main`
    grid-area: content;
    display: block;
    padding: ${(props) => `${props.theme.spacing(4)} ${props.theme.spacing(6)} `};
`;

export const StyledDrawerFooterNew = styled.footer`
    grid-area: footer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: ${(props) => `0px ${props.theme.spacing(6)} `};
`;
